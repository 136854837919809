import type { ReactElement } from 'react';
import type { ChakraProps } from '@chakra-ui/react';
import { chakra, forwardRef } from '@chakra-ui/react';

type IconProps = ChakraProps & {
  icon: () => React.ReactNode;
};

export const Icon = forwardRef<IconProps, 'svg'>((props, ref): ReactElement => {
  const { boxSize = '2rem', icon, fill = 'currentColor', ...rest } = props;
  return (
    <chakra.svg
      ref={ref}
      as="svg"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      boxSize={boxSize}
      fill={fill}
      {...(rest as any)}
    >
      {typeof icon === 'function' ? icon() : icon}
    </chakra.svg>
  );
});
