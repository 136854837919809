import type {
  MapCluster,
  MapInfo,
  MapNode,
  MapGroup,
} from '~/types/graphika-types';
import { unparse } from 'papaparse';
import { Flex, Icon, BodyText } from '~/components';
import { colors } from '~/styles';
import DownloadIcon from '~/public/icons/Download-2.svg';
import { Analytics } from '~/lib/analytics';
import { getNodeAccountLink, getPlatformName } from '~/lib/platform';

type Props = {
  clusters: MapCluster[];
  followsNodeIds: string[];
  groups: MapGroup[];
  map: MapInfo;
  nodes: MapNode[];
};

export function DownloadCSV({
  clusters,
  followsNodeIds,
  groups,
  map,
  nodes,
}: Props) {
  const handleCSVDownload = () => {
    const followNodes = nodes.filter((node) =>
      followsNodeIds.includes(node.node_source_id)
    );
    const data = followNodes.map((node) => ({
      node_id: node.node_source_id,
      name: node.name,
      username: node.username,
      profile_image: node.profile_image_url,
      group: groups.find((group) => group.id === node.group_id)?.name ?? '',
      cluster:
        clusters.find((cluster) => cluster.id === node.cluster_id)?.name ?? '',
      platform: getPlatformName(node.platform),
      URL: getNodeAccountLink(node),
    }));
    const sortedByGroupsClusters = data.sort(
      (a, b) =>
        a.group.localeCompare(b.group) || a.cluster.localeCompare(b.cluster)
    );
    const csv = unparse(sortedByGroupsClusters);
    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', `${map.name}-nodes.csv`);
    a.click();
    a.remove();
    Analytics.event('mapInteraction', {
      action: 'active-node-csv-download',
      map: map.name ?? '',
      map_id: (map.id ?? '').toString(),
    });
  };

  return (
    <Flex
      align="center"
      as="button"
      cursor="pointer"
      gap={2}
      justify="center"
      mt={2}
      onClick={handleCSVDownload}
      w="100%"
    >
      <Icon icon={DownloadIcon} fill={colors.white} boxSize={6} />
      <BodyText>Download CSV of highlighted nodes</BodyText>
    </Flex>
  );
}
