import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { signOut as googleSignOut } from 'next-auth/react';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import { Box, Center, useColorMode } from '@chakra-ui/react';
import { clear } from 'idb-keyval';
import { setUserInfoAction } from '~/redux/userSlice';
import { useAppDispatch } from '~/redux/store';
import { clearLocalUserState, getUserInfo, tickleSession } from '~/lib/auth';
import { UNPROTECTED_PATHS } from '~/constants/routing';
import { Spinner } from '~/components/elements';
import { useAnalytics } from '~/lib/analytics';
import { useIsNavigator, useUser } from '~/lib/hooks/useUser';

type Props = React.PropsWithChildren<{}>;

export function AuthCheck({ children }: Props): ReactElement {
  const [hasPermission, setHasPermission] = useState(false);
  const router = useRouter();
  const user = useUser();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const colorMode = useColorMode();
  const isNavigator = useIsNavigator();

  useEffect(() => {
    (async () => {
      const logout = async () => {
        try {
          await googleSignOut({ redirect: false });
        } catch (e) {
          console.error('authcheck logout error ', e);
        } finally {
          clearLocalUserState();
          dispatch(setUserInfoAction(undefined));
        }
      };
      setHasPermission(false);
      if (UNPROTECTED_PATHS.includes(router.pathname)) {
        setHasPermission(true);
        return;
      }
      if (user.hasCheckedAuthStatus && user.userInfo) {
        setHasPermission(true);
        return;
      }
      if (user.hasCheckedAuthStatus && !user.userInfo) {
        const { pathname, asPath } = router;
        const query =
          pathname === '/' || pathname.includes('/login')
            ? {}
            : {
                prev: asPath,
              };
        clear();
        router.push({
          pathname: '/login',
          query,
        });
        return;
      }
      try {
        const loggedIn = await tickleSession();
        if (loggedIn) {
          const userInfo = await getUserInfo();
          dispatch(setUserInfoAction(userInfo));
          return;
        } else {
          await logout();
          return;
        }
      } catch (e) {
        await logout();
      }
    })();
  }, [user, router.pathname, dispatch, router, queryClient]);

  useAnalytics(
    'userProperties',
    {
      user_id: user.userInfo?.id ?? -1,
      user_role: user.userInfo?.role ?? 'n/a',
      user_org: user.userInfo?.org?.id
        ? `${user.userInfo?.org?.id} (${user.userInfo?.org?.name})`
        : 'n/a',
      color_mode: colorMode.colorMode,
    },
    [user.userInfo, colorMode.colorMode]
  );

  useEffect(() => {
    const { email, org } = user.userInfo ?? {};
    Sentry.setUser({
      email: email ?? '',
      org: org?.name,
    });
  }, [user.userInfo]);

  if (!hasPermission) {
    return (
      <Box w="100vw" pt={64}>
        <Center>
          <Spinner size="xl" />
        </Center>
      </Box>
    );
  }
  return <Box className={isNavigator ? 'navigator' : ''}>{children}</Box>;
}
