export const UNPROTECTED_PATHS = ['/login'];

/**
 * default Signal View without mapFilterId or any selection
 */
export const defaultSignalViewParams = {
  mapFilterId: undefined,
  selectedGroupId: undefined,
  selectedRedditId: undefined,
  selectedTelegram: undefined,
};
