import type { MapInfo } from '~/types/graphika-types';
import {
  differenceInDays,
  endOfDay,
  format,
  formatDistance,
  parse,
  subDays,
} from 'date-fns';
import { HgBasicQueryParams } from './hypergraph';

export const CORE_API_DATA_RETENTION = parseInt(
  process.env.NEXT_PUBLIC_CORE_DATA_RETENTION_DAYS
);

/**
 * formats date to `2022-11-21` etc
 */
export const formatApiDate = (date: Date): string => format(date, 'yyyy-MM-dd');

/** parse date from `2022-11-21` etc */
export const parseApiDate = (dateStr: string) =>
  parse(dateStr, 'yyyy-MM-dd', new Date());

/**
 * formats date to `2022-11-21` for UTC for `Date` from any timezone
 */
export const formatApiUTCDate = (date: Date) =>
  startOfUTC(date).toISOString().slice(0, 10);

/**
 * ensure midnight UTC time
 */
export const startOfUTC = (date: Date | string) =>
  new Date(new Date(date).setUTCHours(0, 0, 0, 0));

/**
 * formats date to `Nov 21, 2021` etc
 */
export const formatShortDate = (date: Date): string =>
  format(date, 'MMM d, yyyy');

/**
 * formats date to `Nov. 21, 2021` etc
 */
export const formatShortDateWithDot = (date: Date): string =>
  format(date, 'MMM. d, yyyy');

/**
 * formats date to `November 21, 2021` etc
 */
export const formatLongDate = (date: Date): string =>
  format(date, 'MMMM d, yyyy');

/**
 * formats date to `11/21/2021` etc
 */
export const formatNumericDate = (date: Date): string =>
  format(date, 'MM/dd/yyyy');

/**
 * formats date to `August 22, 2022` etc
 */
export const formatHumanShortDate = (date: Date) =>
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const formatHumanLongDate = (date?: Date) => {
  try {
    if (!date || date.getFullYear() === 1969) {
      return 'Invalid date';
    }
    return date.toLocaleTimeString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZoneName: 'short',
    });
  } catch (_) {
    return 'Invalid date';
  }
};

/**
 * Format date like so: April 12, 2023 4:03PM EST
 */
export const formatDateTime = (date: Date) => {
  return new Date(date).toLocaleString(undefined, {
    dateStyle: 'long',
    timeStyle: 'short',
  });
};

export const TODAY = subDays(endOfDay(new Date()), 0);
export const YESTERDAY = subDays(endOfDay(new Date()), 1);
export const THIRTY_DAYS_AGO = subDays(endOfDay(new Date()), 30);

export const getDateSpan = (endDate: Date | string, days: number) => {
  const end = new Date(endDate);
  const start = subDays(endOfDay(end), days);
  return {
    start_date: formatApiDate(start),
    end_date: formatApiDate(end),
  };
};

/**
 * simply adds 'Today' and 'Yesterday' to `formatDistance` from 'date-fns
 */
export const differenceInWords = (date: Date, baseDate: Date) => {
  const difference = differenceInDays(date, baseDate);
  switch (difference) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    default:
      return formatDistance(new Date(date), new Date(baseDate), {
        addSuffix: true,
      });
  }
};

export const getMapStartDateUTC = (map: MapInfo) =>
  formatApiUTCDate(startOfUTC(subDays(new Date(), CORE_API_DATA_RETENTION)));
export const getMapEndDateUTC = (map: MapInfo) =>
  formatApiUTCDate(startOfUTC(new Date()));

export const isOutsideLiveCoreDataWindow = (
  params: Partial<HgBasicQueryParams>
) => {
  const { startDate, endDate } = params;
  if (!startDate || !endDate) return true;
  return (
    startDate <
      formatApiUTCDate(subDays(new Date(), CORE_API_DATA_RETENTION)) ||
    endDate < formatApiUTCDate(subDays(new Date(), CORE_API_DATA_RETENTION))
  );
};

/**
 * generates array of `yyyy-MM-dd` independent of timezone
 */
export function generateDateArray(startDate: Date, endDate: Date) {
  function formatDate(date: Date) {
    const d = new Date(date);
    let month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
    let day = d.getUTCDate().toString().padStart(2, '0');
    let year = d.getUTCFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  const dateArray: string[] = [];
  let currentDate = new Date(
    Date.UTC(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate()
    )
  );
  while (currentDate <= endDate) {
    dateArray.push(formatDate(currentDate));
    currentDate = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate() + 1
      )
    );
  }

  return dateArray;
}

/**
 * only used in `<ChartTooltip>` for timezone independent date rendering
 */

export const getDateStr = (dateStr: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  try {
    const month = months[parseInt(dateStr.slice(5, 7)) - 1].toUpperCase();
    const day = parseInt(dateStr.slice(8, 10));
    const year = parseInt(dateStr.slice(0, 4));
    return `${month} ${day}, ${year}`;
  } catch (e) {
    return '';
  }
};
