import type { PropsWithChildren } from 'react';
import type { ButtonProps } from '@chakra-ui/react';
import type { Narrative, Signal } from '~/types/graphika-types';
import {} from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { unparse as unparseCSV } from 'papaparse';
import {
  BodyText,
  Box,
  Button,
  Divider as ChakraDivider,
  Flex,
  Icon,
  SubtitleHeader,
} from '~/components';
import { escapeFileName } from '~/lib/utils';
import { useRemoveAllSignals } from '~/queries/mutations/signal';
import { colors } from '~/styles';
import PlusIcon from '~/public/icons/Plus.svg';
import DataDownloadIcon from '~/public/icons/Download.svg';

type Props = {
  narrative: Narrative;
  setSignalModalOpen: (isOpen: boolean) => void;
};

export function SignalTopMenu({ narrative, setSignalModalOpen }: Props) {
  const { signals } = narrative;
  const removeAllSignals = useRemoveAllSignals(narrative.id);
  const gray3 = useColorModeValue(colors.warmGray[3], colors.coolGray[4]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);

  const onDownloadClick = () => {
    downloadSignals(signals, `${narrative.title}-signals.csv`);
  };

  const onRemoveAll = () => {
    const response = confirm(
      'Are you sure you want to delete all the signals?'
    );
    if (response) {
      removeAllSignals.mutate({});
    }
  };

  const hasNoSignals = signals.length === 0;
  return (
    <Box mb={4} position="sticky" top="0px" zIndex={1}>
      <Box bg={gray6} px={4} py={1} borderBottom={`1px solid ${gray3}`}>
        <Flex justify="space-between" align="center">
          <SubtitleHeader>
            Signal list ({signals.length} signal{signals.length !== 1 && 's'})
          </SubtitleHeader>
          <Flex align="center" gap={1}>
            <ActionButton onClick={() => setSignalModalOpen(true)}>
              <Icon
                icon={PlusIcon}
                mt={'16px'}
                boxSize={7}
                mr={-1}
                fill={primary}
              />
              <BodyText color={primary}>Add signals</BodyText>
            </ActionButton>
            <Divider />
            <ActionButton disabled={hasNoSignals} onClick={onDownloadClick}>
              <Icon icon={DataDownloadIcon} boxSize={5} mr={2} />
              <BodyText color={hasNoSignals ? gray3 : primary}>
                Export signals
              </BodyText>
            </ActionButton>
            <Divider />
            <ActionButton
              disabled={hasNoSignals}
              onClick={onRemoveAll}
              color={colors.status.red}
            >
              <BodyText
                as="button"
                color={colors.status.red}
                opacity={hasNoSignals ? 0.7 : 1}
              >
                Remove all
              </BodyText>
            </ActionButton>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

const ActionButton = ({
  children,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const primary = useColorModeValue(colors.g.primary, colors.g.light);

  return (
    <Button
      variant="text"
      noFocusStyle
      bg="inherit"
      color={primary}
      alignContent="center"
      _hover={{ filter: 'brightness(0.8)' }}
      {...(props as any)}
    >
      {children}
    </Button>
  );
};

const Divider = () => (
  <ChakraDivider orientation="vertical" h={6} mx={1} my={0} />
);

function downloadSignals(signals: Signal[], filename: string): void {
  const file = new Blob([signalsToCSV(signals)], { type: 'text/csv' });
  const url = window.URL.createObjectURL(file);

  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', escapeFileName(filename));
  a.click();
}

function signalsToCSV(signals: Signal[]): string {
  return unparseCSV(signals, {
    columns: [
      'signal_type',
      'signal_platform',
      'target_type',
      'target_value',
      'target_platform',
      'category',
      'notes',
      'translation',
    ],
    header: true,
  });
}
