import { produce } from 'immer';
import {
  DependencyList,
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createTrackedSelector } from 'react-tracked';
import {
  type AnyZodObject,
  type SafeParseError,
  type SafeParseReturnType,
  type SafeParseSuccess,
  type z,
} from 'zod';
import { create, type StateCreator, type StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { useLazyRef } from '~/lib/hooks/useLazyRef';
import { capitalize } from './text';

type UseStore<T> = {
  (): T;
  <U>(selector?: (store: T) => U): U;
};
type UseStoreContext<T> = UseStore<T> & Pick<StoreApi<T>, 'subscribe'>;
type UseCreateStore<T> = (initialState?: T | (() => T)) => UseStoreContext<T>;
type UseStoreProvider<T> = FunctionComponent<{
  children?: ReactNode | undefined;
  value: UseStoreContext<T>;
}>;

type CreateStoreContainerResult<T> = {
  Provider: UseStoreProvider<T>;
  useCreateStore: UseCreateStore<T>;
  useStore: UseStore<T>;
  useSubscribe: (
    listener: (state: T, prevState: T) => void,
    deps: DependencyList,
    /** You only need to pass this if you're using it outside of the Provider. It will correctly give you a type error if you're passing it when not needed. */
    store?: UseStoreContext<T>
  ) => void;
};
export function createStoreContainer<
  T,
  Actions extends Record<string, (...args: any[]) => T | void>
>(
  defaultInitialState: T | (() => T),
  actions: ActionsDef<T, Actions>,
  options: CreateStoreOptions = {}
): CreateStoreContainerResult<T & Actions> {
  options.name ||= 'store';

  const useCreateStore = (initialState: T | (() => T) = defaultInitialState) =>
    useLazyRef(() => {
      const useZustandStore = createStore<T, Actions>(
        typeof initialState === 'function'
          ? (initialState as Function)()
          : initialState,
        actions,
        { name: options.name }
      );
      const useTrackedStore = createTrackedSelector(useZustandStore);
      return Object.assign(
        function useStore(selector) {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          if (selector) return useZustandStore(useShallow(selector));
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useTrackedStore();
        } as UseStore<T & Actions>,
        { subscribe: useZustandStore.subscribe.bind(useZustandStore) }
      );
    }).current;

  const Context = createContext<UseStoreContext<T & Actions>>(undefined!);
  Context.displayName = capitalize(options.name) + 'Context';
  const Provider: UseStoreProvider<T & Actions> = (props) => (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
  );
  Provider.displayName = capitalize(options.name) + 'Provider';

  return {
    Provider,
    useCreateStore,
    useStore: ((selector: (state: T & Actions) => any) =>
      useContext(Context)(selector)) as any,
    useSubscribe: (listener, deps, store) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const useStore = store ?? useContext(Context);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => useStore.subscribe(listener), [...deps]);
    },
  };
}

type CreateStoreOptions = { name?: string };
export function createStore<
  T,
  Actions extends Record<string, (...args: any[]) => T | void>
>(
  initialState: T,
  actions: ActionsDef<T, Actions>,
  options: CreateStoreOptions = {}
): UseStore<T & Actions> &
  Pick<StoreApi<T & Actions>, 'subscribe' | 'getState'> {
  return create<
    T & Actions,
    [['zustand/devtools', never], ['zustand/immer', never]]
  >(
    devtools(
      immer((set, get, api) => ({
        ...initialState,
        ...(convertActions(actions, set, get, api) as any),
      })),
      { name: options.name }
    )
  );
}

/** Use this to type actions when they are defined outside the store.
 * Defining outside the store enables you to call an action from within another action
 * without triggering a subsequent store transaction. */
export type ActionsDef<
  T,
  Actions extends Record<string, (...args: any[]) => T | void>
> = {
  [K in keyof Actions]: Actions[K] extends (...args: infer U) => void
    ? (state: T, ...args: U) => void
    : never;
};
const convertActions = <T extends ActionsDef<any, any>>(
  actions: T,
  ...[set, get]: Parameters<StateCreator<any, [['zustand/devtools', never]]>>
) =>
  Object.fromEntries(
    Object.entries(actions).map(([key, fn]) => [
      key,
      (...args: any[]) =>
        set((state: any) => fn(state, ...args), undefined, key),
    ])
  ) as T extends ActionsDef<any, infer Actions> ? Actions : never;

type Updater<T> = (update: T | ((value: T) => T | void)) => void;
export function useImmerState<T>(initialState: T | (() => T)): [T, Updater<T>] {
  const [state, setState] = useState(initialState);
  const updateState: Updater<T> = useCallback((update) => {
    if (typeof update !== 'function') return setState(update);
    setState((prev) => produce(prev, update as any));
  }, []);
  return [state, updateState];
}

type SafeParseResult<I, O> = SafeParseReturnType<I, O>;
type UseValidStateResult<TSchema extends AnyZodObject, T = z.infer<TSchema>> =
  | [T, Updater<Partial<T>>, true, SafeParseSuccess<z.output<TSchema>>]
  | [Partial<T>, Updater<Partial<T>>, false, SafeParseError<z.input<TSchema>>];

export function useValidState<
  TSchema extends AnyZodObject,
  T = z.infer<TSchema>
>(
  schema: TSchema,
  initialValue: Partial<T> | (() => Partial<T>)
): UseValidStateResult<TSchema, T> {
  const [value, setValue] = useImmerState(initialValue);
  const parsed = useMemo<SafeParseResult<z.input<TSchema>, z.output<TSchema>>>(
    () => schema.safeParse(value),
    [schema, value]
  );
  return [value as T, setValue, parsed.success, parsed as any];
}
