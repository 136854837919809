import type { DownloadParams } from '@graphika/map-viewer';
import type { MapViewMode } from '@graphika/map-viewer/dist/types';
import type { MapGroup, MapInfo } from '~/types/graphika-types';
import { ListItem, UnorderedList, useOutsideClick } from '@chakra-ui/react';
import { useEventListeners, useMvConfig } from '@graphika/map-viewer';
import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import {
  BodyText,
  Box,
  Checkbox,
  ConfirmationModal,
  Flex,
  Icon,
} from '~/components';
import { CustomizeMap } from '~/components/maps/CustomizeMap';
import { Analytics } from '~/lib/analytics';
import { useWindowSize } from '~/lib/hooks';
import { getRoleForUser } from '~/lib/routing';
import CogIcon from '~/public/icons/Cog.svg';
import DropdownArrowDownIcon from '~/public/icons/Navigation/Down.svg';
import WandMagicSparklesIcon from '~/public/icons/WandMagicSparkles.svg';
import { colors } from '~/styles';

type Props = {
  viewMode: MapViewMode;
  setViewMode: (viewMode: MapViewMode) => void;
  map: MapInfo;
  setDownload: (type: DownloadParams) => void;
  setShowNodeSearchModal: (open: boolean) => void;
  isShowingLabels: boolean;
  groups: MapGroup[];
};

export function MapViewSelection({
  viewMode,
  setViewMode,
  map,
  setDownload,
  setShowNodeSearchModal,
  isShowingLabels,
  groups,
}: Props) {
  const [viewModeDropdownIsOpen, setViewModeDropdownIsOpen] = useState(false);
  const [actionDropdownIsOpen, setActionDropdownIsOpen] = useState(false);
  const [showHdLabelWarning, setShowHdLabelWarning] = useState(false);
  const [viewHasChanged, setViewHasChanged] = useState(false);
  const [showMapCustomization, setShowMapCustomization] = useState(false);
  const actionDropdownRef = useRef<HTMLDivElement>(null);
  const viewModeRef = useRef<HTMLDivElement>(null);
  const mvConfig = useMvConfig();
  const isAnalystOrAdmin = ['analyst', 'admin'].includes(
    getRoleForUser() ?? ''
  );

  const { width } = useWindowSize();
  const isNarrowWindow = (width ?? 0) < 1400;

  useOutsideClick({
    ref: actionDropdownRef,
    handler: () => setActionDropdownIsOpen(false),
  });
  useOutsideClick({
    ref: viewModeRef,
    handler: (e) => setViewModeDropdownIsOpen(false),
  });

  const mapInfo = useMemo(
    () => ({ map: map.name ?? '', map_id: (map.id ?? '').toString() }),
    [map]
  );

  const handleViewClick = (viewMode: MapViewMode) => {
    setViewMode(viewMode);
    mvConfig.setOrbitControls({ autoRotate: false });
    setViewModeDropdownIsOpen(false);
    Analytics.event('mapInteraction', {
      action: 'view',
      label: viewMode,
      ...mapInfo,
    });
  };

  const handleMapImageDownload = (quality: DownloadParams['quality']) => {
    setDownload({
      quality,
      filename: `${map.id}_map`,
      transparent: true,
      fileFormat: 'png',
    });
    setActionDropdownIsOpen(false);
    Analytics.event('download', {
      action: quality === 'hd' ? 'map_image_hd' : 'map_image',
      ...mapInfo,
    });
  };

  const handleResetView = () => {
    mvConfig.controls.reset();
    mvConfig.setOrbitControls({ autoRotate: false });
    setViewHasChanged(false);
    Analytics.event('mapInteraction', { action: 'reset-view', ...mapInfo });
  };

  const AutoRotate = useMemo(
    () =>
      function AutoRotate() {
        return (
          <>
            <Checkbox
              isDisabled={viewMode === '2d'}
              isChecked={Boolean(mvConfig.orbitControls.autoRotate)}
              onChange={(e) => {
                mvConfig.setOrbitControls({ autoRotate: e.target.checked });
                setViewHasChanged(true);
                Analytics.event('mapInteraction', {
                  action: 'auto-rotate',
                  ...mapInfo,
                });
              }}
            />
            <BodyText ml={1} color={colors.black}>
              Auto rotate
            </BodyText>
          </>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapInfo, mvConfig.orbitControls, mvConfig.setOrbitControls, viewMode]
  );

  useEventListeners(
    mvConfig.events,
    { onControlsEnd: () => setViewHasChanged(true) },
    [setViewHasChanged]
  );

  if (!mvConfig.isReady) return null;
  return (
    <Flex
      position="absolute"
      top="66px"
      left="422px"
      gap={2}
      zIndex={11}
      sx={{ userSelect: 'none' }}
    >
      <Container>
        <Flex
          gap={3}
          align="center"
          onClick={() => setShowNodeSearchModal(true)}
          cursor="pointer"
        >
          <Icon icon={WandMagicSparklesIcon} boxSize={4} />
          <BodyText ml={1} color={colors.black}>
            Search Map
          </BodyText>
        </Flex>
      </Container>
      <Container>
        <Flex
          justify="space-between"
          w="135px"
          onClick={() => setViewModeDropdownIsOpen(!viewModeDropdownIsOpen)}
          cursor="pointer"
        >
          <BodyText
            pt={0.5}
            color={colors.black}
          >{`${viewMode.toUpperCase()} view`}</BodyText>
          <Box mr={-2}>
            <Icon
              icon={DropdownArrowDownIcon}
              boxSize={6}
              color={colors.g.primary}
              transform={viewModeDropdownIsOpen ? `rotate(180deg)` : ''}
            />
          </Box>
        </Flex>
      </Container>
      {viewModeDropdownIsOpen && (
        <Box
          position="absolute"
          top="32px"
          left="146px"
          bg={colors.white}
          mt={2}
          py={1}
          borderRadius={16}
          w="168px"
          overflow="hidden"
          ref={viewModeRef}
        >
          <UnorderedList ml={0} maxHeight="332px" minW="136px">
            <ListItem {...listItemStyle} onClick={() => handleViewClick('3d')}>
              3D view
            </ListItem>
            <ListItem {...listItemStyle} onClick={() => handleViewClick('2d')}>
              2D view
            </ListItem>
          </UnorderedList>
        </Box>
      )}
      {viewMode !== '2d' && !isNarrowWindow && (
        <Container>
          <AutoRotate />
        </Container>
      )}
      <Flex
        w="50px"
        bg={colors.white}
        borderRadius={16}
        align="center"
        justify="center"
        pt={3}
        pl={3.5}
        h="32px"
      >
        <Icon
          icon={CogIcon}
          color={colors.g.primary}
          onClick={() => setActionDropdownIsOpen(!actionDropdownIsOpen)}
          cursor="pointer"
        />
      </Flex>
      {actionDropdownIsOpen && (
        <Box
          position="absolute"
          top="32px"
          left={viewMode === '2d' || isNarrowWindow ? '150px' : '294px'}
          bg={colors.white}
          mt={2}
          py={1}
          borderRadius={16}
          overflow="hidden"
          ref={actionDropdownRef}
        >
          <UnorderedList ml={0} maxHeight="332px" minW="220px">
            {isNarrowWindow && (
              <ListItem {...listItemStyle}>
                <Flex align="center">
                  <AutoRotate />
                </Flex>
              </ListItem>
            )}
            <ListItem
              {...listItemStyle}
              onClick={() => handleMapImageDownload('basic')}
            >
              Download Map Image
            </ListItem>
            {isAnalystOrAdmin && (
              <>
                <ListItem
                  {...listItemStyle}
                  onClick={() => {
                    if (isShowingLabels) {
                      setShowHdLabelWarning(true);
                    } else {
                      handleMapImageDownload('hd');
                    }
                  }}
                >
                  Download HD Map Image
                </ListItem>
                <ListItem
                  {...listItemStyle}
                  onClick={() => {
                    setActionDropdownIsOpen(false);
                    setShowMapCustomization(true);
                  }}
                >
                  Customize Map
                </ListItem>
              </>
            )}
          </UnorderedList>
        </Box>
      )}
      {showHdLabelWarning && (
        <ConfirmationModal
          title="Please disable any labels for HD export"
          message="This feature will be implemented in the future."
          okText="OK"
          onClose={() => {
            setShowHdLabelWarning(false);
          }}
          cancelText=""
          onCancel={() => {}}
          onOK={() => {
            setShowHdLabelWarning(false);
          }}
        />
      )}
      {viewHasChanged && (
        <Flex align="center" onClick={handleResetView} ml={2} cursor="pointer">
          <BodyText color="white">Reset View</BodyText>
        </Flex>
      )}
      <CustomizeMap
        onClose={() => setShowMapCustomization(false)}
        showMapCustomization={showMapCustomization}
        map={map}
        groups={groups}
      />
    </Flex>
  );
}

const Container = ({ children }: PropsWithChildren<{}>) => (
  <Flex
    h="32px"
    borderRadius="32px"
    bg={colors.white}
    align="center"
    px={4}
    py={1.5}
  >
    {children}
  </Flex>
);

const listItemStyle = {
  px: 4,
  py: 2,
  my: 2,
  listStyleType: 'none',
  _hover: { bg: colors.g.lightLavender },
  color: colors.black,
  cursor: 'pointer',
};
