import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { Image } from '@chakra-ui/react';
import { BodyText, Box, Button, Flex, Markdown } from '~/components';
import { colors } from '~/styles';
import ArrowLeft from '~/public/icons/Arrow-Left.svg';
import ArrowRight from '~/public/icons/Arrow-Right.svg';

type Props = {
  currentSectionIndex: number;
  currentSubsection: string;
  images: { src: string; alt: string }[];
  setVideoModalUrl: (url: string) => void;
};

export function ImageCarousel({
  currentSectionIndex,
  currentSubsection,
  images,
  setVideoModalUrl,
}: Props) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const router = useRouter();
  const baseUrl = `${process.env.NEXT_PUBLIC_ASSETS_URL}/narrative_feeds/${router.query.feedId}/images`;

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [currentSectionIndex, currentSubsection]);

  useEffect(() => {
    setContentLoaded(false);
  }, [currentImageIndex]);

  if (images.length === 0) {
    return <Box />;
  }
  const { alt, src } = images[currentImageIndex % images.length];
  const isVideo = new RegExp(/.mp4|.ogg/).test(src);

  if (images.length >= 1) {
    return (
      <Flex w="100%" h="100%" direction="column" justifyContent="space-between">
        <Box w="100%" h="50%" maxW={960} margin="20vh auto ">
          {isVideo ? (
            <Flex h="100%" margin="0 auto" justify="center" position="relative">
              <video
                muted
                controls
                loop
                ref={videoRef}
                src={`${baseUrl}/${src}`}
                onCanPlay={() => setContentLoaded(true)}
                onMouseEnter={() => videoRef.current?.play()}
                onMouseLeave={() => videoRef.current?.pause()}
              />
              <Flex
                position="absolute"
                top={0}
                right={0}
                align="center"
                justify="center"
                w="120px"
                h="40px"
              >
                <Box
                  px={4}
                  py={2}
                  borderRadius={8}
                  border="1px solid white"
                  cursor="hover"
                  as="button"
                  onClick={() => setVideoModalUrl(src)}
                  opacity={0.4}
                  _hover={{
                    opacity: 1,
                    borderWidth: '2px',
                    transition: '0.2s ease-in-out',
                  }}
                >
                  <BodyText color={colors.white}>Open Player</BodyText>
                </Box>
              </Flex>
            </Flex>
          ) : (
            <Image
              src={`${baseUrl}/${src}`}
              alt={alt}
              objectFit="contain"
              h="100%"
              margin="0 auto"
              onLoad={() => setContentLoaded(true)}
            />
          )}
          {contentLoaded && (
            <Box
              margin="20px auto"
              overflow="auto"
              width="640px"
              maxH="160px"
              overflowY="auto"
              sx={{
                colorScheme: 'dark',
              }}
            >
              <Markdown color={colors.g.pale}>{alt}</Markdown>
            </Box>
          )}
        </Box>
        <Flex justify="center" h={8}>
          {images.length > 1 && (
            <Flex align="center">
              <Button
                variant="text"
                color={colors.g.lightLavender}
                onClick={() =>
                  currentImageIndex === 0
                    ? setCurrentImageIndex(images.length - 1)
                    : setCurrentImageIndex(currentImageIndex - 1)
                }
                icon={ArrowLeft}
              />
              <BodyText color={colors.g.lightLavender}>
                {(currentImageIndex % images.length) + 1}/{images.length}
              </BodyText>
              <Button
                variant="text"
                color={colors.g.lightLavender}
                onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                icon={ArrowRight}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }
  return <Box />;
}
