import type { Session } from 'next-auth';
import type { AppProps } from 'next/app';
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SessionProvider as AuthSessionProvider } from 'next-auth/react';
import { useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Provider as ReduxProvider } from 'react-redux';
import {
  AuthCheck,
  Layout,
  QueryParamProvider,
  RouteCheck,
} from '~/components';
import { UserTracking } from '~/lib/analytics';
import { useRouteHistory } from '~/lib/hooks/useRouteHistory';
import { QueryClientProvider } from '~/lib/queryClient';
import store from '~/redux/store';
import { colors, components, global, textStyles } from '~/styles';
import '~/styles/treeview-plugin.css';
import '~/styles/pdf.css';

const theme = extendTheme({
  colors,
  config: { initialColorMode: 'light' },
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif',
    monospace: 'Roboto Mono, monospace',
  },
  textStyles,
  styles: {
    global,
  },
  components: { ...components },
});

type Props = AppProps<{ session: Session }>;
function MyApp({ Component, pageProps }: Props) {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_BUILD_ID !== 'development') {
      const [BUILD_ID, BUILD_DATE] =
        process.env.NEXT_PUBLIC_BUILD_ID!.split('--');
      console.log('GRAPHIKA PLATFORM BUILD ID', BUILD_ID);
      console.log('GRAPHIKA PLATFORM BUILD DATE', BUILD_DATE);
    }
  }, []);

  useRouteHistory();

  return (
    <QueryClientProvider>
      <AuthSessionProvider session={pageProps.session}>
        <ReduxProvider store={store}>
          <UserTracking />
          <ChakraProvider theme={theme}>
            <CSSReset />
            <AuthCheck>
              <RouteCheck>
                <QueryParamProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </QueryParamProvider>
              </RouteCheck>
            </AuthCheck>
          </ChakraProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </ReduxProvider>
      </AuthSessionProvider>
    </QueryClientProvider>
  );
}
export default MyApp;
