import {
  Box,
  ContentRenderer,
  Flex,
  TitleHeader,
  Image,
  BodyText,
  Link,
} from '~/components';
import { scrollIntoView } from '~/lib/docs';
import { colors } from '~/styles';

type DocSectionProps = { title?: string; content: string };

export function DocSection({ content, title }: DocSectionProps) {
  return (
    <Box p={4} bg="#2c264a" color={colors.white} my={4} borderRadius={8}>
      <TitleHeader mb={2}>{title}</TitleHeader>
      <ContentRenderer fontFamily="monospace">{content}</ContentRenderer>
    </Box>
  );
}

type NarrowImageProps = { src: string; alt: string; width?: string };

export function NarrowImage({ src, alt, width = '100%' }: NarrowImageProps) {
  return (
    <Flex justify="center">
      <Image
        w={width}
        src={src}
        alt={alt}
        my={6}
        align="center"
        display="block"
        borderRadius={8}
      />
    </Flex>
  );
}

export function LearnMore() {
  return (
    <BodyText color={colors.white} my={6} fontStyle="italic">
      Learn more about insights in the
      <Link
        href="/docs"
        color={colors.g.light}
        onClick={() =>
          scrollIntoView(
            document.querySelector(`[data-section-content="FAQs"]`)
          )
        }
      >
        {' '}
        FAQ{' '}
      </Link>
      section below.
    </BodyText>
  );
}
