import type { MapGroup } from '~/types/graphika-types';
import { Center, Collapse, useColorModeValue } from '@chakra-ui/react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Children, PropsWithChildren, useState } from 'react';
import { BodyText, Box, Flex, Icon, Tooltip } from '~/components';
import { Analytics } from '~/lib/analytics';
import { useSegmentTree } from '~/lib/stores/segment-tree';
import { useColorModeValues } from '~/lib/utils';
import EyeIcon from '~/public/icons/FaEye.svg';
import EyeHiddenIcon from '~/public/icons/FaEyeHidden.svg';
import TagIcon from '~/public/icons/FaTag.svg';
import TagHiddenIcon from '~/public/icons/FaTagHidden.svg';
import { colors } from '~/styles';

type Props = PropsWithChildren<{
  group: MapGroup;
  withLabelOption?: boolean;
  withActiveOption?: boolean;
}>;
export function GroupTreeItem({
  children,
  group,
  withLabelOption,
  withActiveOption,
}: Props) {
  const segmentTree = useSegmentTree();
  const [isHovered, setIsHovered] = useState(false);
  const [gray1, gray2, gray4, white] = useColorModeValues(
    [colors.warmGray[1], colors.coolGray[5]],
    [colors.warmGray[2], colors.coolGray[4]],
    [colors.warmGray[4], colors.coolGray[3]],
    [colors.white, colors.coolGray[2]]
  );
  if (!segmentTree.groups[group.id]) return null;
  const { active, showLabel, isOpen } = segmentTree.groups[group.id];
  const { toggleGroup } = segmentTree;

  const withClusters = !!Children.count(children);
  const textColor =
    active || active === null ? gray1 : isHovered ? gray1 : gray2;
  const bgColor = isHovered && (active || active === null) ? gray4 : white;

  return (
    <Box>
      <Flex
        align="center"
        px={2}
        py={2}
        cursor="pointer"
        bg={bgColor}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        position="relative"
        onClick={() => {
          Analytics.event('widgetQuery', {
            action: 'group_change',
            label: withActiveOption ? 'active' : 'showLabel',
          });
          toggleGroup({
            id: group.id,
            param: withActiveOption ? 'active' : 'showLabel',
            ignoreCluster: true,
          });
        }}
      >
        <Flex
          gap={1}
          onClick={(e) => {
            if (withClusters) {
              e.stopPropagation();
              e.preventDefault();
              toggleGroup({ id: group.id, param: 'isOpen' });
            }
          }}
          flexGrow={1}
        >
          <GroupBox
            bg={active || active === null ? `#${group.hex_color}` : gray2}
            isExpanded={isOpen}
            isHovered={isHovered}
            withClusters={withClusters}
          />
          <BodyText color={textColor} fontWeight={500} lineHeight="16px">
            {group.name}
          </BodyText>
        </Flex>
        <Flex
          align="center"
          gap={2}
          bg={bgColor}
          position="absolute"
          right={2}
          top={0}
          bottom={0}
        >
          {withLabelOption && (!showLabel || isHovered) && (
            <Icon
              icon={showLabel ? TagIcon : TagHiddenIcon}
              boxSize={4}
              fill={gray1}
            />
          )}
          {withActiveOption && (!active || isHovered) && (
            <Tooltip
              label={active ? 'Hide' : 'Show'}
              variant="monochrome"
              hasArrow={false}
            >
              <Icon
                icon={active ? EyeIcon : EyeHiddenIcon}
                boxSize={4}
                fill={gray1}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <Box mb={2}>
        <Collapse in={isOpen}>{children}</Collapse>
      </Box>
    </Box>
  );
}

type GroupBoxProps = {
  bg: string;
  isExpanded: boolean;
  isHovered: boolean;
  withClusters?: boolean;
};
function GroupBox({ bg, isExpanded, isHovered, withClusters }: GroupBoxProps) {
  const white = useColorModeValue(colors.white, colors.coolGray[2]);
  return (
    <Tooltip
      label={withClusters ? (isExpanded ? 'Collapse' : 'Expand') : ''}
      variant="monochrome"
      hasArrow={false}
    >
      <Center minW={4} minH={4}>
        <Center
          w="10px"
          h="10px"
          borderRadius={2}
          transform={`rotate(${isExpanded ? '90deg' : '0deg'})`}
          transition="0.2s"
          bg={bg}
        >
          {isHovered && withClusters && (
            <FontAwesomeIcon icon={faCaretRight} color={white} size="xs" />
          )}
        </Center>
      </Center>
    </Tooltip>
  );
}
