import type { MapCluster } from '~/types/graphika-types';
import { useContext, useState } from 'react';
import { BodyText, Box, Flex, Icon, Tooltip } from '~/components';
import { Analytics } from '~/lib/analytics';
import { useColorModeValues } from '~/lib/utils';
import EyeIcon from '~/public/icons/FaEye.svg';
import EyeHiddenIcon from '~/public/icons/FaEyeHidden.svg';
import TagIcon from '~/public/icons/FaTag.svg';
import TagHiddenIcon from '~/public/icons/FaTagHidden.svg';
import { colors } from '~/styles';
import { useSegmentTree } from '~/lib/stores/segment-tree';

type Props = {
  cluster: MapCluster;
  withLabelOption?: boolean;
  withActiveOption?: boolean;
};
export function ClusterTreeItem({
  cluster,
  withLabelOption,
  withActiveOption,
}: Props) {
  const segmentTree = useSegmentTree();
  const [isHovered, setIsHovered] = useState(false);
  const [gray1, gray2, gray4, white] = useColorModeValues(
    [colors.warmGray[1], colors.coolGray[5]],
    [colors.warmGray[2], colors.coolGray[4]],
    [colors.warmGray[4], colors.coolGray[3]],
    [colors.white, colors.coolGray[2]]
  );

  if (!segmentTree.clusters[cluster.id]) return null;
  const { active, showLabel } = segmentTree.clusters[cluster.id] ?? {};
  const { toggleCluster } = segmentTree;

  const textColor = active ? gray1 : isHovered ? gray1 : gray2;
  const bgColor = isHovered && active ? gray4 : white;
  return (
    <Box>
      <Flex
        align="center"
        pr={2}
        pl={7}
        py={2}
        cursor="pointer"
        bg={bgColor}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        position="relative"
      >
        <Flex gap={2}>
          <Box
            h="10px"
            mt="3px"
            minW="10px"
            borderRadius={2}
            bg={active ? `#${cluster.hex_color}` : gray2}
          />
          <BodyText
            color={textColor}
            fontWeight={500}
            lineHeight="16px"
            cursor="default"
          >
            {cluster.name}
          </BodyText>
        </Flex>
        {isHovered && (
          <Flex
            align="center"
            gap={2}
            bg={bgColor}
            position="absolute"
            right={2}
            top={0}
            bottom={0}
          >
            {withLabelOption && (
              <Icon
                icon={showLabel || showLabel === null ? TagIcon : TagHiddenIcon}
                boxSize={4}
                cursor="pointer"
                fill={gray1}
                onClick={() => {
                  Analytics.event('widgetQuery', {
                    action: 'cluster_change',
                    label: 'showLabel',
                  });
                  toggleCluster({
                    id: cluster.id,
                    param: 'showLabel',
                    ignoreParent: true,
                  });
                }}
              />
            )}
            {withActiveOption && (
              <Tooltip
                label={active ? 'Hide' : 'Show'}
                variant="monochrome"
                hasArrow={false}
              >
                <Icon
                  icon={active || active === null ? EyeIcon : EyeHiddenIcon}
                  boxSize={4}
                  cursor="pointer"
                  fill={gray1}
                  onClick={() => {
                    Analytics.event('widgetQuery', {
                      action: 'cluster_change',
                      label: 'active',
                    });
                    toggleCluster({
                      id: cluster.id,
                      param: 'active',
                      ignoreParent: true,
                    });
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
