import store from '~/redux/store';

export const adminRouteRegex = new RegExp(/^\/admin/);
export const assetsRouteRegex = new RegExp(/^\/assets/);
export const authoringRouteRegex = new RegExp(/\/authoring$/);
export const docsRouteRegex = new RegExp(/^\/docs/);
export const reportsRouteRegex = new RegExp(/\/reports$/);
export const mapviewerRouteRegex = new RegExp(/\/maps/);
export const feedsRouteRegex = new RegExp(/\/feeds/);
export const loginRouteRegex = new RegExp(/^\/login/);
export const settingsRouteRegex = new RegExp(/^\/settings/);
export const accessDeniedRouteRegex = new RegExp(/^\/access-denied/);
export const widgetRouteRegex = new RegExp(/^\/widget/);

export const getIsReaderSpace = (pathname: string) =>
  accessDeniedRouteRegex.test(pathname) ||
  assetsRouteRegex.test(pathname) ||
  docsRouteRegex.test(pathname) ||
  feedsRouteRegex.test(pathname) ||
  settingsRouteRegex.test(pathname) ||
  mapviewerRouteRegex.test(pathname) ||
  widgetRouteRegex.test(pathname);

export const getIsDiscoveryViewerSpace = (pathname: string) =>
  getIsReaderSpace(pathname);

export const getIsAnalystSpace = (pathname: string) =>
  getIsReaderSpace(pathname) ||
  authoringRouteRegex.test(pathname) ||
  reportsRouteRegex.test(pathname);

export const getIsAdminSpace = (pathname: string) =>
  getIsAnalystSpace(pathname) || adminRouteRegex.test(pathname);

export const getRoleForUser = () => {
  const state = store.getState();
  return state.user?.userInfo?.role;
};

const getDefaultRouteForRole = ({ asObject }: { asObject?: boolean }) => {
  const role = getRoleForUser();
  const { user } = store.getState();
  if (user.userInfo?.password_reset_required) {
    return '/settings?view=password';
  }
  if (role === 'analyst' || role === 'admin') {
    const pathname = '/authoring';
    return asObject ? { pathname } : pathname;
  }
  if (role === 'intelligence reader' || role === 'discovery viewer') {
    return '/feeds';
  }
  if (role === '') {
    return '/access-denied';
  }
  return '/login';
};

export const getDefaultRouteForUser = () => {
  return getDefaultRouteForRole({ asObject: false });
};

export const useDefaultRouteForUser = ({
  asObject = false,
}: {
  asObject?: boolean;
}) => {
  return getDefaultRouteForRole({ asObject });
};
