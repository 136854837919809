import type { ComponentsWithoutNodeOptions } from 'rehype-react/lib/complex-types';
import type { CSSObject } from '@chakra-ui/react';
import {
  ListItem,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { PluggableList, unified } from 'unified';
import {
  BodyText,
  Box,
  Image,
  Link,
  Markdown,
  PageHeader,
  SubtitleHeader,
  TitleHeader,
} from '~/components';
import { colors } from '~/styles';
import { useOriginal } from '~/lib/utils';

export type ComponentMap = ComponentsWithoutNodeOptions['components'];

type Props = {
  children: string;
  stripTags?: (keyof JSX.IntrinsicElements & string)[];
  textProps?: TextProps;
  truncateLines: boolean;
  plugins?: PluggableList;
};

export function Html({
  children,
  stripTags: _stripTags = [],
  textProps = {},
  truncateLines,
  plugins = [],
}: Props) {
  const [result, setResult] = useState<ReactElement>(<></>);
  const components = useComponents(textProps, truncateLines);
  const stripTags = useOriginal(_stripTags);

  useEffect(() => {
    const allowedTags = (
      [
        'a',
        'b',
        'blockquote',
        'br',
        'code',
        'div',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hr',
        'i',
        'li',
        'ol',
        'mark',
        'p',
        'span',
        'strong',
        'u',
        'ul',
        'img',
      ] as (keyof JSX.IntrinsicElements)[]
    ).filter((tag) => !stripTags.includes(tag));

    const transform = () =>
      unified()
        .use(rehypeParse, { fragment: true })
        .use(rehypeSanitize, {
          ...defaultSchema,
          tagNames: allowedTags,
          strip: stripTags,
          attributes: {
            ...defaultSchema.attributes,
            '*': ['className'],
            img: ['alt', 'src'],
          },
        })
        .use(rehypeReact, {
          createElement: React.createElement,
          components,
        })
        .use(plugins)
        .process(children);

    transform().then(({ result }) => setResult(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, components, stripTags]);

  return result;
}

function useComponents(
  textProps: TextProps,
  truncateLines: boolean
): ComponentMap {
  const { colorMode } = useColorMode();
  const black = useColorModeValue(colors.black, colors.white);
  const highlightedTextBg = useColorModeValue(colors.g.lavender, colors.g.dark);
  const olListColor = useColorModeValue(colors.g.plum, colors.g.pale);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);

  const styleObject: CSSObject = truncateLines
    ? {
        _notFirst: {
          display: 'none',
        },
      }
    : {};
  return useMemo<ComponentMap>(
    () => ({
      mark: ({ children }) => {
        return (
          <BodyText
            color={colors.status.red}
            fontWeight={700}
            display="inline-block"
          >
            {children}
          </BodyText>
        );
      },
      span: ({ children, className }) => {
        return className === 'highlight' ? (
          <Text as="span" bg={highlightedTextBg}>
            {children}
          </Text>
        ) : className === 'editor-text-underline' ? (
          <Text as="u">{children}</Text>
        ) : (
          <span>{children}</span>
        );
      },
      p: ({ children }) => {
        return (
          <BodyText
            {...(textProps as any)}
            noOfLines={truncateLines ? 8 : undefined}
            sx={styleObject}
          >
            {children}
          </BodyText>
        );
      },
      em: ({ children }) => {
        return (
          <BodyText
            as="span"
            {...(textProps as any)}
            fontStyle="italic"
            className="content-renderer-em"
          >
            {children}
          </BodyText>
        );
      },
      code: ({ children }) => {
        const childrenAsStr = children as string[];
        const containsTextHighlight = childrenAsStr.filter(
          (child) => child.indexOf('!!') === 0
        );
        if (containsTextHighlight.length > 0) {
          return (
            <Text as="span" {...(textProps as any)} bg={highlightedTextBg}>
              {childrenAsStr.map((child) => child.replace('!!', ''))}
            </Text>
          );
        } else {
          return (
            <Text as="span" {...(textProps as any)} fontStyle="italic">
              {children}
            </Text>
          );
        }
      },
      a: ({ children, href }) => {
        let sanitizedHref = href;
        if (href?.indexOf('%60!!') !== -1) {
          sanitizedHref = sanitizedHref
            ?.replace('%60!!', '')
            .replace('%60', '');
        }
        return (
          <BodyText
            {...(textProps as any)}
            as="span"
            display="inline"
            onClick={(e) => e.stopPropagation()}
          >
            <Link target="_blank" href={sanitizedHref}>
              {children}
            </Link>
          </BodyText>
        );
      },
      ul: ({ children }) => {
        return <UnorderedList my={1}>{children}</UnorderedList>;
      },
      ol: ({ children }) => {
        return (
          <OrderedList my={1} ml="18px" spacing={2} color={olListColor}>
            {children}
          </OrderedList>
        );
      },
      img: ({ src, alt }) => {
        return (
          <Box>
            <Link href={src} target="_blank" rel="noopener">
              <Image
                src={src}
                alt={alt}
                maxW="80%"
                margin="1rem auto"
                maxH="50vh"
              />
            </Link>
            <Markdown
              textAlign="center"
              maxW="75%"
              margin="-8px auto 8px"
              color={gray1}
            >
              {alt || ''}
            </Markdown>
          </Box>
        );
      },
      li: ({ children }) => {
        return (
          <ListItem
            fontSize={textProps.fontSize}
            textStyle="BodyText"
            mb={1}
            color={olListColor}
          >
            {children}
          </ListItem>
        );
      },
      h1: ({ children }) => <PageHeader>{children}</PageHeader>,
      h2: ({ children }) => <TitleHeader>{children}</TitleHeader>,
      h3: ({ children }) => <SubtitleHeader>{children}</SubtitleHeader>,
      blockquote: ({ children }) => (
        <Box borderLeftWidth="3px" borderLeftColor={black} pl={4} my={3}>
          {children}
        </Box>
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colorMode, textProps]
  );
}
