import {} from 'react';
import { unparse } from 'papaparse';
import { useColorModeValue } from '@chakra-ui/react';
import { Box, Flex, SubtitleHeader, Icon } from '~/components';
import { Analytics } from '~/lib/analytics';
import { formatNumber } from '~/lib/numbers';
import { colors } from '~/styles';
// TODO: Add InfoIcon once copy in Figma is confirmed
import InfoIcon from '~/public/icons/InfoCircle2.svg';
import DownloadIcon from '~/public/icons/Download.svg';
import { PostsData } from './utils';

type Props = { postsData: PostsData['data']; postCount: number | undefined };

export function PostsSidebarTop({ postsData, postCount }: Props) {
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);

  const handleCsvDownload = () => {
    Analytics.event('postWidget', {
      action: 'csv_download',
    });
    const data = postsData.posts.map((post) => {
      const groupName =
        postsData.groups.find((group) => group.id === post.creator.group_id)
          ?.name ?? 'n/a';
      return {
        'post url': post.url,
        'post Id': post.id,
        platform: post.platform,
        date: post.created_at,
        'account username': post.creator.display_name,
        'account id': post.creator.id,
        'post text': post.text,
        'view count': post.view_count,
        'account group': groupName,
        'repost of': post.repost_of,
        'reply to': post.reply_to,
        'quote of': post.quote_of,
        mentions: post.mentions,
        hashtags: post.hashtags,
        cashtags: post.cashtags,
        domains: post.domains,
        URLs: post.urls,
        images: post.images,
        videos: post.videos,
        'repost count': post.reference_count?.total ?? 'n/a',
      };
    });

    const csv = unparse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', `posts.csv`);
    a.click();
    a.remove();
  };

  return (
    <Flex
      justify="space-between"
      align="center"
      px={4}
      py={2}
      borderBottom={`1px solid ${gray4}`}
    >
      <Flex align="center" gap={1}>
        {!!postCount && (
          <Flex align="center" gap={1}>
            <SubtitleHeader fontWeight={700}>
              {postCount === 5000 ? '5,000+' : formatNumber(postCount)}
            </SubtitleHeader>
            <SubtitleHeader mr={2}>
              Post{postCount !== 1 ? 's' : ''}
            </SubtitleHeader>
          </Flex>
        )}
      </Flex>
      <Box as="button" onClick={handleCsvDownload}>
        <Icon icon={DownloadIcon} boxSize={6} fill={primary} />
      </Box>
    </Flex>
  );
}
