import type { MapGroup } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import Image from 'next/image';
import { BodyText, Box, Flex } from '~/components';
import { DocsLinkPopover } from '~/components/docs';
import { useActivityChart } from '~/components/elements/Editor/plugins';
import { totalMapActivityGroup } from '~/components/elements/Editor/plugins/dynamic-insights';
import { Analytics } from '~/lib/analytics';
import { usePostUrlQueryParams } from '~/lib/hooks';
import TotalActivityBoxImg from '~/public/TotalActivityBoxImg.png';
import { colors } from '~/styles';
import { ChartNodeHoverInfo } from './ActivityChart';

type Props = {
  groups: MapGroup[];
  hoveredSegment: ChartNodeHoverInfo | null;
};

export function ChartGroupLegend({ groups, hoveredSegment }: Props) {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  // the post sidebar is synced to the URL search params in this function
  const [urlQueryParams, setUrlQuery] = usePostUrlQueryParams();
  const { mode, params, widgetId } = useActivityChart();

  const handleGroupClick = (segment: MapGroup) => {
    const { startDate, endDate, mapId, term, sort } = params;
    Analytics.event('postWidget', {
      action: 'ac_group_legend_selection',
      label: segment.name,
    });
    setUrlQuery(
      {
        segment_id: segment.id.includes(
          urlQueryParams.segment_id?.replace('group_', '')
        )
          ? undefined
          : segment.id === totalMapActivityGroup.id
          ? segment.id
          : 'group_' + segment.id,
        map_id: mapId,
        start_date: startDate,
        end_date: endDate,
        widgetId,
        query: term,
        sort,
      },
      'replaceIn'
    );
  };
  const isShowingPostSidebar = urlQueryParams.widgetId === widgetId;

  return (
    <Flex
      gap={6}
      flexWrap="wrap"
      justify="center"
      align="center"
      mt={4}
      rowGap={1}
      maxH="58px"
      overflowY="auto"
      className="maxH-on-pdf"
    >
      {mode === 'reader' && groups.length > 0 && (
        <Box mr={-5} className="hide-on-pdf">
          <DocsLinkPopover
            placement="top"
            text="<p>This chart shows the number of daily posts that match this query in the selected map. <a href='/docs?subsection=Interactive%20Charts'>Learn more here</a>.</p>"
            boxSize="14px"
          />
        </Box>
      )}
      {groups.map((group) => {
        const isFaded = isShowingPostSidebar
          ? (hoveredSegment && group.id !== hoveredSegment?.segment.id) ||
            (!!urlQueryParams.segment_id &&
              urlQueryParams.segment_id.replace('group_', '') !==
                group.id.replace('group_', ''))
          : false;
        return (
          <Flex
            key={group.name}
            align="center"
            gap={2}
            px={2}
            onClick={() => handleGroupClick(group)}
            cursor="pointer"
            opacity={isFaded ? 0.3 : 1}
          >
            {group.id === totalMapActivityGroup.id ? (
              <TotalActivityBox />
            ) : (
              <Box
                h="10px"
                w="10px"
                borderRadius={2}
                bg={`#${group.hex_color}`}
                transitionDuration="250ms"
              />
            )}
            <BodyText color={gray1} fontWeight={500} transitionDuration="250ms">
              {group.name}
            </BodyText>
          </Flex>
        );
      })}
    </Flex>
  );
}

const TotalActivityBox = () => (
  <Image
    src={TotalActivityBoxImg}
    alt="total activity"
    height={10}
    width={10}
  />
);
