import type {
  MapCluster,
  MapGroup,
  MapNode,
  Narrative,
} from '~/types/graphika-types';
import { unparse } from 'papaparse';

export type HgRelevantMapNodesSnapshot = {
  id: string;
  type: 'relevant_map_nodes';
  parameters: {
    map_id: string;
    start_date: string; // yyyy-MM-dd
    end_date: string; // yyyy-MM-dd
    query: string;
    sort?: string;
  };
  data: HgRelevantMapNodesData;
};

export type HgMapNode = MapNode & { matches_search: boolean };

export type HgRelevantMapNodesData = {
  map: {
    id: string;
    name: string;
  };
  groups: Pick<MapGroup, 'id' | 'name' | 'hex_color' | 'position'>[];
  clusters: Pick<MapCluster, 'id' | 'name' | 'position' | 'group_id'>[];
  nodes: HgMapNode[];
};

export const downloadRelevantMapNodesCsv = ({
  data,
  insight,
}: {
  data: HgRelevantMapNodesData;
  insight: Narrative;
}) => {
  const matchingNodes = data.nodes
    .filter((node) => node.matches_search)
    .sort((a, b) => b.radius - a.radius);
  const csvData =
    matchingNodes.map((node) => ({
      username: node.username,
      group:
        data.groups.find((group) => group.id === node.group_id)?.name ?? 'n/a',
      platform: node.platform,
    })) ?? [];
  const csv = unparse(csvData);
  const blob = new Blob([csv], { type: 'text/csv' });
  const a = document.createElement('a');
  a.setAttribute('href', window.URL.createObjectURL(blob));
  a.setAttribute('download', `${insight.title}.csv`);
  a.click();
  a.remove();
};
