import type {
  DownloadParams,
  GNode,
  MVNode,
  MapViewMode,
  MapViewerLabel,
} from '@graphika/map-viewer';
import type { NodeScaleConfig } from '@graphika/map-viewer/dist/types';
import type { ReactElement } from 'react';
import type { FollowsType } from '~/components/maps/FollowsSelection';
import type {
  MapCluster,
  MapGroup,
  MapInfo,
  MapNode,
} from '~/types/graphika-types';
import {
  DownloadPlugin,
  HoverNodePlugin,
  LabelsPlugin,
  MapViewerComposer,
  getNodeScale,
  indexBy,
  useBoundingRect,
  useMvConfig,
} from '@graphika/map-viewer';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';
import { Flex } from '~/components';
import {
  HighlightNodePlugin,
  MapNodePopoverPlugin,
  ScreenshotOverlay,
} from '~/components/maps';
import { MapControlInfo } from '~/components/maps/MapControlInfo';
import { StylePlugin } from '~/components/maps/StylePlugin';
import { colors } from '~/styles';

type Props = {
  nodes: MapNode[];
  clusters: MapCluster[];
  groups: MapGroup[];
  highlightedNode?: MapNode;
  setHighlightedNode: (node: MapNode | undefined) => void;
  labels: MapViewerLabel[];
  viewMode: MapViewMode;
  download?: DownloadParams;
  setDownload: (download: DownloadParams | undefined) => void;
  map: MapInfo;
  followType: FollowsType;
};

const config3796: NodeScaleConfig = {
  globalScale: 0.7,
  minNodeSize: 1.25,
};

const defaultNodeScaleConfig: NodeScaleConfig = {
  globalScale: 1.25,
  minNodeSize: 1.5,
};

const noopParser = (node: MVNode) => node as MVNode;

export function MapViewerContainer({
  nodes,
  clusters,
  groups,
  highlightedNode,
  setHighlightedNode,
  labels,
  viewMode,
  download,
  setDownload,
  map,
  followType,
}: Props): ReactElement {
  const router = useRouter();
  const parsedNodes = useMemo(() => {
    return parseApiNodes(nodes, clusters);
  }, [nodes, clusters]);
  const setScale = useMvConfig((state) => state.setScale);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasBoundingRect = useBoundingRect(canvasContainerRef);

  const nodeScaleConfig =
    parseInt(router.query.mapId as string) === 3796
      ? config3796
      : defaultNodeScaleConfig;

  useEffect(() => {
    const newScale = getNodeScale({
      canvasBoundingRect,
      globalScale: nodeScaleConfig.globalScale,
    });
    setScale(newScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasBoundingRect]);

  return (
    <Flex
      bg={colors.mapViewer.bg}
      w="100%"
      h="100%"
      ref={canvasContainerRef}
      id="canvas-container"
      justify="center"
    >
      {download?.quality === 'hd' && <ScreenshotOverlay />}
      <MapViewerComposer
        data={parsedNodes}
        parser={noopParser}
        nodeScaleConfig={nodeScaleConfig}
        viewMode={viewMode}
      >
        <HoverNodePlugin minNodeSize={nodeScaleConfig.minNodeSize} />
        <LabelsPlugin
          labels={nodes.length === 0 ? [] : labels}
          distance={0.7}
        />
        <MapNodePopoverPlugin
          clusters={clusters}
          groups={groups}
          nodes={nodes}
        />
        <DownloadPlugin download={download} setDownload={setDownload} />
        <HighlightNodePlugin
          highlightedNode={highlightedNode}
          setHighlightedNode={setHighlightedNode}
          nodes={nodes}
          map={map}
        />
        {nodes.length && (
          <StylePlugin
            highlightedNode={highlightedNode}
            nodes={nodes}
            map={map}
            followType={followType}
          />
        )}
      </MapViewerComposer>
      <MapControlInfo />
    </Flex>
  );
}

export function parseApiNodes(
  nodes: MapNode[],
  clusters: MapCluster[]
): GNode[] {
  const clusterById = indexBy(clusters, (cluster) => cluster.id);
  return nodes.map((n, i) => ({
    id: n.node_source_id,
    index: i,
    tags: { cluster: n.cluster_id, group: n.group_id },
    coords2d: n.coords_2d,
    coords3d: n.coords_3d,
    radius: n.radius,
    color: clusterById[n.cluster_id]?.hex_color
      ? `#${clusterById[n.cluster_id]!.hex_color}`
      : '#ffffff',
    alpha: 1,
  }));
}
