import type { BoxProps } from '@chakra-ui/react';
import { chakra, useColorModeValue } from '@chakra-ui/react';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  $convertFromMarkdownString,
  QUOTE,
  TRANSFORMERS,
} from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { ComponentProps, ReactElement, useMemo, useState } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeStringify from 'rehype-stringify';
import { unified } from 'unified';
import { Box, Flex } from '~/components';
import { ActivityChartWrapper } from '~/components/dynamic-insights/activity-chart';
import { RelevantMapNodesWrapper } from '~/components/dynamic-insights/relevant-map-nodes';
import { transformLexicalFormatting } from '~/lib/html';
import { debounceFn } from '~/lib/utils';
import { colors } from '~/styles';
import { lexicalTheme } from '~/styles/lexical-theme';
import { Toolbar } from './Toolbar';
import {
  ClickableLinkPlugin,
  DebugPlugin,
  DynamicWidgetNode,
  DynamicWidgetsPlugin,
  EditablePropPlugin,
  GImagesPastePlugin,
  GImagesPlugin,
  GRtfPastePlugin,
  GraphikaLinkPlugin,
  HorizontalRuleNode,
  HorizontalRulePlugin,
  HotKeysPlugin,
  TreeViewPlugin,
  WordCountPlugin,
} from './plugins';
import { GRAPHIKA_IMAGE, ImageNode } from './plugins/gimages';

const MD_TRANSFORMERS = [GRAPHIKA_IMAGE, ...TRANSFORMERS, QUOTE];

function $convertLexicalFromHTML(editor: LexicalEditor, html: string) {
  if (!html.startsWith('<'))
    return $convertFromMarkdownString(html, MD_TRANSFORMERS);

  const preprocessedHtml = unified()
    .use(rehypeParse, { fragment: true })
    .use(transformLexicalFormatting)
    .use(rehypeStringify)
    .processSync(html)
    .toString();

  const parser = new DOMParser();
  const dom = parser.parseFromString(preprocessedHtml, 'text/html');
  const nodes = $generateNodesFromDOM(editor, dom);
  $getRoot().append(...nodes);
}

const RichText = chakra(RichTextPlugin);
const Editable = chakra(ContentEditable);
const Composer = chakra(LexicalComposer);

function onError(error: Error) {
  console.log(error);
}

type Props = {
  narrativeId: number;
  namespace: string;
  onChange: (html: string) => void;
  value: string;
  disabled?: boolean;
};
export function Editor({
  narrativeId,
  namespace,
  onChange,
  value,
  disabled,
}: Props): ReactElement {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const initialConfig: ComponentProps<typeof LexicalComposer>['initialConfig'] =
    {
      editable: !disabled,
      namespace,
      theme: lexicalTheme,
      onError,
      editorState: (editor: LexicalEditor) =>
        $convertLexicalFromHTML(editor, value || '<p/>'),
      nodes: [
        AutoLinkNode,
        HeadingNode,
        HorizontalRuleNode,
        LinkNode,
        ListItemNode,
        ListNode,
        QuoteNode,
        ImageNode,
        DynamicWidgetNode,
      ],
    };
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[1]);
  const gray6 = useColorModeValue(colors.warmGray[6], '#20232A');
  const border = `1px solid ${gray4}`;

  function handleChange(state: EditorState, editor: LexicalEditor) {
    state.read(() => {
      const html = $generateHtmlFromNodes(editor);
      onChange(html);
    });
  }
  const fullscreenStyleWrapper: BoxProps = isFullscreen
    ? {
        position: 'fixed',
        top: '46px',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        bg: gray4,
      }
    : {};

  const fullscreenStyle: BoxProps = isFullscreen
    ? {
        w: '960px',
        margin: '16px auto',
        borderBottomRadius: 8,
        overflow: 'auto',
        h: '100%',
      }
    : { minH: '360px' };

  const debouncedHandleChange = debounceFn(handleChange, 1000);

  return (
    <Flex {...(fullscreenStyleWrapper as any)} direction="column">
      <Box display="flex" flexDirection="column" {...fullscreenStyle}>
        <Composer initialConfig={initialConfig}>
          <Toolbar
            isFullscreen={isFullscreen}
            setIsFullscreen={setIsFullscreen}
          />
          <RichText
            key={isFullscreen ? 'editor-fullscreen' : 'editor-compact'}
            contentEditable={
              <Editable
                px={4}
                py={4}
                overflowY="auto"
                h={isFullscreen ? 'calc(100%-2px) !important' : '100%'}
                flexGrow={1}
                bg={gray6}
                fontSize="14px"
                borderLeft={border}
                borderRight={border}
                borderBottom={`1px dashed ${gray4}`}
                resize="none"
              />
            }
            placeholder={<div></div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <DynamicWidgetsPlugin
            components={{
              activityChart: ActivityChartWrapper,
              relevantMapNodes: RelevantMapNodesWrapper,
            }}
            listeners={useMemo(
              () => ({
                onError(event) {
                  // Handle widget errors here
                  console.log(event.detail.error);
                },
              }),
              []
            )}
          />
          <GImagesPlugin />
          <GImagesPastePlugin />
          <GRtfPastePlugin />
          <EditablePropPlugin editable={!disabled} />
          <ClickableLinkPlugin />
          <GraphikaLinkPlugin />
          <HistoryPlugin />
          <HorizontalRulePlugin />
          <HotKeysPlugin />
          <LinkPlugin />
          <ListPlugin />
          <OnChangePlugin onChange={debouncedHandleChange} />
          <DebugPlugin />
          <WordCountPlugin />
          {process.env.NODE_ENV === 'development' ? <TreeViewPlugin /> : ''}
        </Composer>
      </Box>
    </Flex>
  );
}
