import {
  HTMLChakraProps,
  VisuallyHidden,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { Box } from '~/components';
import { colors } from '~/styles';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export function Spinner({
  color = colors.g.primary,
  emptyColor = colors.warmGray[3],
  label = 'Loading ...',
  size = 'md',
  ...props
}: {
  color?: string;
  emptyColor?: string;
  label?: string;
  size?: Size;
} & HTMLChakraProps<'div'>) {
  const opacity = 0.2;
  const dots = 8;
  const sizes: { [key: string]: number } = {
    xs: 10,
    sm: 15,
    md: 20,
    lg: 30,
    xl: 50,
  };
  const diameter = sizes[size] || sizes['md'];

  const scaleAnimationDuration = 2; // sec
  const scaleAnimationFrames = usePrefersReducedMotion()
    ? keyframes`
      0%, 70%, 100% { transform: scale(0.9) }
      50% { transform: scale(1) }
      `
    : keyframes`
      0%, 70%, 100% { transform: scale(0.5) }
      10% { transform: scale(1) }
    `;

  const colorAnimationFrames = usePrefersReducedMotion()
    ? keyframes`
        0%, 100% { opacity: 0.2 }
        50% { opacity: 0.4  }
        `
    : keyframes`
        0%, 100% { background: ${emptyColor}; opacity: 0.1 }
        50% { background: ${color}; opacity: 0.6 }
      `;

  return (
    <Box {...(props as any)}>
      <Box
        h={diameter + 'px'}
        m={diameter * 0.2 + 'px'}
        position="relative"
        w={diameter + 'px'}
      >
        {label && <VisuallyHidden>{label}</VisuallyHidden>}
        <Box
          animation={`${colorAnimationFrames} 4.5s infinite linear`}
          bg={color}
          borderRadius="100%"
          display="inline-block"
          height="50%"
          left="25%"
          opacity={opacity}
          position="absolute"
          top="25%"
          width="50%"
        />

        {Array.from({ length: dots }).map((_, idx) => (
          <Box
            key={idx}
            display="inline-block"
            h="100%"
            left={0}
            m="0 auto"
            position="absolute"
            top={0}
            transform={`rotate(${idx / dots}turn)`}
            w="100%"
            _before={{
              animation: `${scaleAnimationFrames} ${scaleAnimationDuration}s infinite linear, ${colorAnimationFrames} 1.9s infinite linear`,
              animationDelay: `${(scaleAnimationDuration * idx) / dots}s`,
              bg: color,
              borderRadius: '100%',
              content: `""`,
              height: '25%',
              opacity: opacity,
              position: 'absolute',
              width: '25%',
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
