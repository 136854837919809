import type {
  Dispatch,
  ForwardRefExoticComponent,
  RefAttributes,
  SetStateAction,
} from 'react';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { AnyZodObject, z } from 'zod';
import { SegmentSelection } from '~/lib/stores/segment-tree';
import { AsyncStatus, useAsync } from '~/lib/utils';

export type DynamicWidgetComponent = ForwardRefExoticComponent<
  RefAttributes<HTMLDivElement>
>;

export type DynamicWidgetConfig<
  TParams extends AnyZodObject = any,
  TData = any,
  TExtras extends any = any,
  TEvents extends Record<string, any> = WidgetEventMap
> = {
  _name: string;
  _paramsSchema: TParams;
  _load: (params: z.infer<TParams>, extras: TExtras) => Promise<any>;
  _createSnapshot(params: z.infer<TParams>, extras: TExtras): Promise<string>;
  _removeSnapshot(id: string): Promise<boolean>;
  _getSnapshot(id: string): Promise<Snapshot<TData> | undefined>;
  _isLatestSnapshot(snapshotParams: any, nodeParams: any): boolean;

  _component: DynamicWidgetComponent;

  /** Defines the type of the widget parameters */
  params<T extends AnyZodObject>(
    _paramsSchema: T
  ): Pick<DynamicWidgetConfig<T, TData, TExtras>, 'events'>;

  /** Defines custom events emitted by the widget */
  events<T extends Record<string, any> = WidgetEventMap>(): Pick<
    DynamicWidgetConfig<TParams, TData, TExtras, T>,
    'extras'
  >;

  /** Defines the type of extra data that may be passed to `_load` and `_createSnapshot`.
   *  This data is not saved on the widget, so there's no need to provide a Zod schema for the type.
   */
  extras<T = void>(): Pick<
    DynamicWidgetConfig<TParams, TData, T, TEvents>,
    'loader'
  >;
  /** Defines how to use the params and extras to load widget data  */
  loader<T extends (params: z.infer<TParams>, extras: TExtras) => Promise<any>>(
    _load: T
  ): Pick<
    DynamicWidgetConfig<TParams, Awaited<ReturnType<T>>, TExtras, TEvents>,
    'snapshot'
  >;

  /** Defines the widget's snapshot behavior. */
  snapshot(callbacks: {
    create(params: z.infer<TParams>, extras: TExtras): Promise<string>;
    remove(id: string): Promise<boolean>;
    get(id: string): Promise<Snapshot<TData> | undefined>;
    /** Used to compare parameters on the lexical node with those returned with the snapshot data (from `get()` above).
     *    This is used in reader mode, where the behavior of `refetch()` depends on if the user has made changes in "playground" mode.
     *    Playground mode allows an intelligence reader to modify the node parameters without saving their changes (and without creating a snapshot).
     *
     *    Note: For now, you should manually specify the type of the parameters returned with the snapshot data.
     */
    isLatest(dataParams: any, nodeParams: z.infer<TParams>): boolean;
  }): DynamicWidgetConfig<TParams, TData, TExtras, TEvents>;
  getSnapshotId(): string | undefined;
};
type Snapshot<TData = any> = { data: TData; params: Record<string, any> };
export type WidgetPresets = Record<string, any>;

export function defineDynamicWidget(
  _name: string
): Pick<DynamicWidgetConfig, 'params'> {
  const config = { _name } as DynamicWidgetConfig<any, any>;

  return {
    params(schema) {
      config._paramsSchema = schema;
      return {
        events() {
          return {
            extras() {
              return {
                loader(load) {
                  config._load = load;
                  return {
                    snapshot(callbacks) {
                      config._createSnapshot = callbacks.create;
                      config._removeSnapshot = callbacks.remove;
                      config._getSnapshot = callbacks.get;
                      config._isLatestSnapshot = callbacks.isLatest;
                      return config;
                    },
                  };
                },
              };
            },
          };
        },
      };
    },
  };
}

export const DynamicWidgetCtx = createContext<DynamicWidgetCtxType>(undefined!);
/** The React context that widgets interact with */
export type DynamicWidgetCtxType<
  TParams = Record<string, any>,
  TData = any,
  TExtras = void,
  TEvents extends Record<string, any> = WidgetEventMap
> = {
  data: TData | undefined;
  status: AsyncStatus;
  refetch(extras: TExtras extends void ? any | void : TExtras): void;
  mode: 'author' | 'reader';
  isSelected: boolean;
  dispatch: WidgetEventDispatch<TEvents>;
  getSnapshotId(): string | undefined;

  isValidParams: boolean;
  params: Partial<TParams>;
  setParams: Updater<Partial<TParams>>;

  caption: string;
  setCaption: Dispatch<SetStateAction<string>>;
  presets: WidgetPresets;
  setPresets: Dispatch<SetStateAction<WidgetPresets>>;
  segments: SegmentSelection;
  setSegments: Dispatch<SetStateAction<SegmentSelection>>;
  getSnapshotId(): string | undefined;
  widgetType?: string;
  widgetId?: string;
};
export type Updater<T> = (update: T | ((value: T) => T | void)) => void;

export type UseDynamicWidgetHook = {
  (): DynamicWidgetCtxType<any>;
  <T>(): DynamicWidgetCtxType<T>;
};

/** Helper that wraps `useContext(DynamicWidgetCtx)` and returns a typed widget hook. */
export function createWidgetHook<T>(): T extends DynamicWidgetConfig<
  infer Params,
  infer Data,
  infer Extras,
  infer Events
>
  ? () => DynamicWidgetCtxType<z.infer<Params>, Data, Extras, Events>
  : never {
  return (() => useContext(DynamicWidgetCtx)) as any;
}

type UseWidgetDataArgs = {
  params: Record<string, any>;
  mode: 'author' | 'reader';
  getSnapshotId: () => string | undefined;
  setSnapshotId: (id: string | undefined) => void;
  immediate: boolean;
};
/**
 * Encapsulates the logic for fetching and snapshoting widget data based on the `config` and provided arguments.
 */
export function useWidgetData<T extends DynamicWidgetConfig>(
  config: T,
  { params, mode, getSnapshotId, setSnapshotId, immediate }: UseWidgetDataArgs
) {
  const fetchData = useCallback(
    async (extras?: any) => {
      const snapshotId = getSnapshotId();
      const snapshot = await (async () => {
        if (!snapshotId) return undefined;
        try {
          return await config._getSnapshot(snapshotId);
        } catch (error) {
          return undefined;
        }
      })();
      const isLatest =
        snapshot && config._isLatestSnapshot(snapshot.params, params);

      if (mode === 'reader') {
        if (!snapshot)
          throw new Error(`Widget '${config._name}' has no snapshot.`);
        return isLatest ? snapshot.data : await config._load(params, extras);
      }

      if (mode === 'author') {
        if (isLatest) return snapshot.data;

        if (snapshot) await config._removeSnapshot(snapshotId!);
        config._createSnapshot(params, extras).then(setSnapshotId);
        return config._load(params, extras);
      }
    },
    [config, mode, params, getSnapshotId, setSnapshotId]
  );

  return useAsync(fetchData, { immediate });
}

/** Events supported by every widget */
export interface WidgetEventMap {
  error: { error: any };
}

export type WidgetEventListeners<T = WidgetEventMap> = {
  [K in keyof (T & WidgetEventMap) as `on${Capitalize<K & string>}`]?: (
    event: CustomEvent<(T & WidgetEventMap)[K]>
  ) => void;
};
type WidgetEventDispatch<T = WidgetEventMap> = <
  K extends keyof (T & WidgetEventMap)
>(
  type: K,
  detail: (T & WidgetEventMap)[K]
) => void;

/** Helper to subscribe to multiple event types on a widget
 * @param target the DOM node or another `EventTarget` being used as the widget event bus
 * @param listeners an object of event listeners to be registered on the target
 *
 * @returns a callback to remove the listeners from the target
 */
export function addWidgetEventListeners<
  T extends Record<string, any> = WidgetEventMap
>(target: EventTarget, listeners: WidgetEventListeners<T>): () => void {
  const unsubscribers = Object.entries(listeners).map(([key, listener]) => {
    const eventName =
      key.charAt(2).toLowerCase() + key.split('').slice(3).join('');

    target.addEventListener(eventName, listener as any);
    return () => target.removeEventListener(eventName, listener as any);
  });

  return () => unsubscribers.forEach((unsub) => unsub());
}

export function useWidgetEventListeners<T extends Record<string, any>>(
  target: EventTarget,
  listeners: WidgetEventListeners<T>
): { dispatch: WidgetEventDispatch<T> } {
  useEffect(() => {
    if (target) return addWidgetEventListeners(target, listeners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, ...Object.values(listeners)]);

  return { dispatch: useWidgetDispatch<T>(target) };
}

/** Convenience hook to create a type-safe `dispatch` function for sending widget events.
 * @param target the `Node` or another `EventTarget` being used as the widget event bus
 */
export function useWidgetDispatch<T extends Record<string, any>>(
  target: EventTarget
): WidgetEventDispatch<T> {
  return useCallback(
    (type, detail) =>
      target.dispatchEvent(new CustomEvent(type as string, { detail })),
    [target]
  );
}
