import type { MapInfo } from '~/types/graphika-types';
import { useColorModeValue, useOutsideClick } from '@chakra-ui/react';
import { addMinutes, subMinutes } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BodyText, Box, Flex } from '~/components';
import { getMapEndDateUTC, getMapStartDateUTC, startOfUTC } from '~/lib/date';
import { useColorModeValues } from '~/lib/utils';
import { colors } from '~/styles';

type DateRange = [Date, Date];

type Props = {
  map: MapInfo;
  value: [string | undefined, string | undefined];
  onChange: (range: DateRange) => void;
  disabled?: boolean;
};
export function DateRangePicker({ map, value, onChange, disabled }: Props) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [minDate, maxDate] = useMemo(
    () => [
      startOfUTC(getMapStartDateUTC(map)),
      startOfUTC(getMapEndDateUTC(map)),
    ],
    [map]
  );
  const [selectedDates, setSelectedDates] = useState<DateRange>([
    startOfUTC(value[0] ?? minDate),
    startOfUTC(value[1] ?? maxDate),
  ]);
  useEffect(() => {
    setSelectedDates([
      startOfUTC(value[0] ?? minDate),
      startOfUTC(value[1] ?? maxDate),
    ]);
  }, [value, minDate, maxDate]);

  const handleDateRangeSelect = (dates: DateRange) => {
    setSelectedDates(dates.map(startOfUTC) as DateRange);
    setShowDatePicker(false);
    onChange(dates);
  };

  const gray2 = useColorModeValue(colors.warmGray[2], colors.coolGray[5]);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[3]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[2]);

  const dateRangeText = `${(selectedDates[0] ?? minDate)
    .toISOString()
    .slice(5, 10)} to ${(selectedDates[1] ?? maxDate)
    .toISOString()
    .slice(5, 10)} `;
  if (disabled) {
    return (
      <Flex align="center" gap={2} ml={2}>
        <BodyText
          minW="30px"
          w="30px"
          className="fix-datepicker-word-wrap-on-pdf"
        >
          from
        </BodyText>
        <BodyText
          cursor="not-allowed"
          bg={gray4}
          color={gray2}
          p={2}
          borderRadius={8}
          _focus={{ outline: 'none' }}
        >
          {dateRangeText}
        </BodyText>
      </Flex>
    );
  }

  return (
    <Flex align="center" gap={2} ml={2} minW="148px">
      <BodyText
        minW="30px"
        w="30px"
        className="fix-datepicker-word-wrap-on-pdf"
      >
        from
      </BodyText>
      <Box position="relative">
        <BodyText
          onClick={() => setShowDatePicker(true)}
          cursor="pointer"
          bg={gray6}
          border={`1px solid ${gray4}`}
          p={2}
          borderRadius={8}
          _focus={{ outline: 'none' }}
        >
          {dateRangeText}
        </BodyText>
        {showDatePicker && (
          <DateRangePickerPopover
            value={selectedDates}
            onChange={handleDateRangeSelect}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </Box>
    </Flex>
  );
}

type PopoverProps = {
  value: DateRange;
  onChange: (dates: DateRange) => void;
  minDate: Date;
  maxDate: Date;
};
function DateRangePickerPopover({
  value,
  onChange,
  minDate,
  maxDate,
}: PopoverProps) {
  const [tempDates, setTempDates] = useState<DateRange>(value);
  const ref = useRef<HTMLDivElement>(null);

  const offsetStart = value[0].getTimezoneOffset();
  const offsetEnd = value[1].getTimezoneOffset();
  const addTimeOffset = useCallback(
    (dates: DateRange): DateRange => [
      addMinutes(dates[0], offsetStart),
      addMinutes(dates[1], offsetEnd),
    ],
    [offsetStart, offsetEnd]
  );
  const removeTimeOffset = useCallback(
    (dates: DateRange): DateRange => [
      subMinutes(dates[0], offsetStart),
      subMinutes(dates[1], offsetEnd),
    ],
    [offsetStart, offsetEnd]
  );

  const [min, max] = useMemo(
    () => addTimeOffset([minDate, maxDate]),
    [minDate, maxDate, addTimeOffset]
  );

  useEffect(() => {
    setTempDates(addTimeOffset(value));
  }, [addTimeOffset, value]);

  const handleDateRangeSelect = (dates: DateRange) => {
    if (dates[0] && dates[1]) onChange(removeTimeOffset(dates));
    setTempDates(dates);
  };

  useOutsideClick({ ref, handler: () => onChange(value) });

  return (
    <Box
      ref={ref}
      borderRadius={16}
      overflow="hidden"
      sx={useDateRangePickerStyles()}
    >
      <DatePicker
        onChange={handleDateRangeSelect}
        startDate={tempDates[0]}
        selected={tempDates[0]}
        endDate={tempDates[1]}
        selectsRange
        inline
        minDate={min}
        maxDate={max}
      />
    </Box>
  );
}

function useDateRangePickerStyles() {
  const [black, white, gray4, lavender, primary] = useColorModeValues(
    [colors.black, colors.white],
    [colors.white, colors.coolGray[2]],
    [colors.warmGray[4], colors.coolGray[2]],
    [colors.g.lightLavender, colors.g.plum],
    [colors.g.primary, colors.g.light]
  );
  return useMemo(
    () => ({
      '.react-datepicker': {
        borderRadius: '16px',
        border: `1px solid ${gray4}`,
        padding: '6px',
        userSelect: 'none',
        position: 'absolute',
        top: '40px',
        zIndex: 100,
        bg: white,
      },
      '.react-datepicker__month-container': {
        bg: white,
        borderRadius: '16px',
      },
      '.react-datepicker__current-month': {
        color: black,
      },
      '.react-datepicker__navigation--next': {
        top: '12px',
      },
      '.react-datepicker__navigation--previous': {
        top: '12px',
      },
      '.react-datepicker__day': {
        color: black,
        margin: '2px 4px',
        '&:hover': {
          borderRadius: '50%',
          background: colors.g.lavender,
          color: colors.black,
        },
      },
      '.react-datepicker__day--in-selecting-range': {
        background: lavender,
        color: black,
        borderRadius: '50%',
        '&:hover': {
          color: black,
        },
      },
      '.react-datepicker__day--in-range': {
        background: lavender,
        color: black,
        borderRadius: '50%',
        '&:hover': {
          background: primary,
          color: white,
        },
      },
      '.react-datepicker__day--range-start, .react-datepicker__day--range-end':
        {
          background: primary,
          color: white,
          borderRadius: '50%',
        },
      '.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end':
        {
          background: primary,
          color: white,
          '&:hover': {
            background: primary,
            color: white,
          },
        },
      '.react-datepicker__day--keyboard-selected': {
        backgroundColor: white,
        borderRadius: '50%',
        color: black,
      },
      '.react-datepicker__day-names': {
        bg: white,
        '.react-datepicker__day-name': {
          color: primary,
          margin: '0 4px',
        },
      },
      '.react-datepicker__header ': {
        borderBottom: 'none',
        background: white,
      },
      'div.react-datepicker__day.react-datepicker__day--disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
    }),
    [black, white, gray4, lavender, primary]
  );
}
