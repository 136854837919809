import type {
  MapViewMode,
  NodeScaleConfig,
} from '@graphika/map-viewer/dist/types';
import type { HgMapNode } from '~/lib/hypergraph';
import type { MapCluster, MapGroup, MapNode } from '~/types/graphika-types';
import { SlideFade, useColorModeValue } from '@chakra-ui/react';
import {
  LabelsPlugin,
  MVNode,
  MapViewerComposer,
  getNodeScale,
  useBoundingRect,
  useMvConfig,
} from '@graphika/map-viewer';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { BodyText, Flex, Icon } from '~/components';
import { useRelevantMapNodes } from '~/components/elements/Editor/plugins/dynamic-insights';
import { parseApiNodes } from '~/components/maps';
import { useSubscribeSegmentTree } from '~/lib/stores/segment-tree';
import { useMapLabels } from '~/pages/maps/[mapId]';
import InfoIcon from '~/public/icons/Info.svg';
import { colors } from '~/styles';
import { MvRelevantNodeStylePlugin } from './MvRelevantNodeStylePlugin';
import { segmentTreeDefaults } from './RelevantMapNodesWrapper';
import { ViewModeControl } from './ViewModeControl';
import { ZoomControl } from './ZoomControl';
import { RMNPopoverPlugin } from './RMNPopoverPlugin';

type Props = {
  clusters: MapCluster[];
  groups: MapGroup[];
  hgNodes: HgMapNode[];
};

const noopParser = (node: MVNode) => node as MVNode;

const widgetNodeScaleConfig: NodeScaleConfig = {
  globalScale: 5.25,
  minNodeSize: 1.5,
};

export function MvWidgetContainer({ clusters, hgNodes = [], groups }: Props) {
  const { segments, presets, mode, data } = useRelevantMapNodes();
  const [scale, setScale, setOrbitControls] = useMvConfig((mv) => [
    mv.scale,
    mv.setScale,
    mv.setOrbitControls,
  ]);
  const [viewMode, setViewMode] = useState<MapViewMode>('2d');
  const [showDragWarning, setShowDragWarning] = useState(false);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasBoundingRect = useBoundingRect(canvasContainerRef);
  const white = useColorModeValue(colors.white, colors.coolGray[2]);

  useEffect(() => {
    setViewMode(presets.viewMode ?? '2d');
  }, [presets.viewMode]);

  const [labels, onChange] = useMapLabels({
    clusters,
    groups,
    initialSegments: segments,
    defaults: segmentTreeDefaults,
  });
  useSubscribeSegmentTree(
    (next) => onChange(next.groups, next.clusters),
    [onChange]
  );
  const nodes: MapNode[] = useMemo(
    () =>
      hgNodes.map((node) => ({
        ...node,
        matches_search: undefined,
      })) ?? [],
    [hgNodes]
  );
  const parsedNodes = useMemo(() => {
    return parseApiNodes(nodes, clusters);
  }, [nodes, clusters]);

  useEffect(() => {
    if (scale !== 1) return;
    setScale(
      getNodeScale({
        canvasBoundingRect,
        globalScale: widgetNodeScaleConfig.globalScale,
      })
    );
    setOrbitControls({ enableZoom: false });
  }, [canvasBoundingRect, scale, setScale, setOrbitControls]);

  const handleMouseMove = (e: MouseEvent) => {
    if (
      e.buttons === 1 &&
      !showDragWarning &&
      !e.shiftKey &&
      viewMode === '2d'
    ) {
      setShowDragWarning(true);
      setTimeout(() => {
        setShowDragWarning(false);
      }, 1000);
    }
  };
  return (
    <Flex
      bg={white}
      h={canvasContainerRef?.current?.clientWidth}
      ref={canvasContainerRef}
      id="canvas-container"
      justify="center"
      position="relative"
      mx={-4}
      onMouseMove={handleMouseMove}
    >
      <SlideFade in={showDragWarning}>
        <DragWarning />
      </SlideFade>
      <MapViewerComposer
        data={parsedNodes}
        parser={noopParser}
        nodeScaleConfig={widgetNodeScaleConfig}
        viewMode={viewMode}
        optimalZoom
      >
        <MvRelevantNodeStylePlugin hgNodes={hgNodes} />
        <RMNPopoverPlugin viewMode={viewMode} hgNodes={data?.nodes ?? []} />
        <LabelsPlugin labels={labels} distance={0.7} />
      </MapViewerComposer>
      <ViewModeControl value={viewMode} onChange={setViewMode} />
      <ZoomControl mode={mode} nodeCount={nodes.length} />
    </Flex>
  );
}

const DragWarning = () => {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  return (
    <Flex
      position="absolute"
      top={2}
      left={4}
      bg={gray6}
      borderRadius={4}
      color={gray1}
      px={2}
      py={1}
      gap={2}
      align="center"
      w="210px"
    >
      <Icon icon={InfoIcon} fill={gray1} boxSize={4} />
      <BodyText>Hold Shift to move the map</BodyText>
    </Flex>
  );
};
