type Format = 'NORMAL' | 'COMPACT' | 'SHORT';

type FormatOptions = Intl.NumberFormatOptions & {
  format?: Format;
  lang?: string | string[];
};

/**
 * formats numbers using Intl.NumberFormat() under the hood
 * allows passing `lang` (i.e. `de-DE`) to override browser locale
 * allows for 3 basic formatting options `NORMAL` (1.110 in `en-us`), `COMPACT` (1.1K in `en-us`) and `SHORT` (`1K` in `en-us`)
 * allows passing in standard Intl.NumberFormat options additionally
 */
export const formatNumber = (
  num: number | string,
  options?: FormatOptions
): string => {
  const language = options?.lang ?? navigator.language;
  const sanitizedOptions = { ...options };
  delete sanitizedOptions.format;
  delete sanitizedOptions.lang;
  if (!options?.format || options.format === 'NORMAL') {
    return new Intl.NumberFormat(language, { ...sanitizedOptions }).format(
      Number(num)
    );
  }
  if (options.format === 'COMPACT') {
    return new Intl.NumberFormat(language, {
      notation: 'compact',
      compactDisplay: 'short',
      ...sanitizedOptions,
    }).format(Number(num));
  }
  if ((options.format = 'SHORT')) {
    return new Intl.NumberFormat(language, {
      notation: 'compact',
      compactDisplay: 'short',
      maximumFractionDigits: 0,
      ...sanitizedOptions,
    }).format(Number(num));
  }
  return num.toString();
};

// Returns a number string of the form "8k"
export function formatCompactNumber(number: number) {
  return formatNumber(number, { format: 'COMPACT' });
}

export function formatNumberDigit1(number: number) {
  return formatNumber(number, { format: 'COMPACT', maximumFractionDigits: 1 });
}
