import type { MapCluster, MapGroup } from '~/types/graphika-types';
import { Center, useColorModeValue, useOutsideClick } from '@chakra-ui/react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { useCallback, useMemo, useRef, useState } from 'react';
import { BodyText, Box, Flex, Icon, Tooltip } from '~/components';
import { totalMapActivityGroup } from '~/components/elements/Editor/plugins/dynamic-insights/widgets';
import { Analytics } from '~/lib/analytics';
import { useSegmentTree } from '~/lib/stores/segment-tree';
import { groupBy, useColorModeValues } from '~/lib/utils';
import TotalActivityBoxImg from '~/public/TotalActivityBoxImg.png';
import EyeIcon from '~/public/icons/FaEye.svg';
import EyeHiddenIcon from '~/public/icons/FaEyeHidden.svg';
import TagIcon from '~/public/icons/FaTag.svg';
import TagHiddenIcon from '~/public/icons/FaTagHidden.svg';
import FilterIcon from '~/public/icons/Filter2.svg';
import { boxShadows, colors } from '~/styles';
import { ClusterTreeItem } from './ClusterTreeItem';
import { GroupTreeItem } from './GroupTreeItem';

type Props = {
  groups: MapGroup[];
  clusters?: MapCluster[];
  withActiveOption?: boolean;
  withLabelOption?: boolean;
  withTotalActivityOption?: boolean;
};

export default function SegmentFilter({
  clusters,
  groups,
  withActiveOption,
  withLabelOption,
  withTotalActivityOption,
}: Props) {
  const segmentTree = useSegmentTree();
  const [showSegmentSelection, setShowSegmentSelection] = useState(false);
  const [isGlobalExpanded, setIsGlobalExpanded] = useState(false);
  const [isGlobalActive, setIsGlobalActive] = useState(false);
  const [isGlobalLabels, setIsGlobalLabels] = useState(false);
  const [primary, gray1, gray4, gray5, gray6, white] = useColorModeValues(
    [colors.g.primary, colors.g.light],
    [colors.warmGray[1], colors.coolGray[5]],
    [colors.warmGray[4], colors.coolGray[3]],
    [colors.warmGray[5], colors.coolGray[2]],
    [colors.warmGray[6], colors.coolGray[2]],
    [colors.white, colors.coolGray[2]]
  );
  const menuRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref, handler: () => setShowSegmentSelection(false) });

  const clustersByGroup = useMemo(
    () =>
      groupBy(
        (clusters ?? []).sort((a, b) => (a.position > b.position ? 1 : -1)),
        (c) => c.group_id
      ),
    [clusters]
  );

  const { toggleRoot, toggleGroup } = segmentTree ?? {};
  const showTotalActivity =
    segmentTree.groups[totalMapActivityGroup.id]?.active;
  const handleToggleExpandGroups = useCallback(() => {
    if (!!clusters?.length) {
      toggleRoot?.({
        param: 'isOpen',
        value: !isGlobalExpanded,
      });
      setIsGlobalExpanded(!isGlobalExpanded);
    }
  }, [toggleRoot, clusters, isGlobalExpanded]);

  const handleToggleShowTotalActivity = useCallback(() => {
    Analytics.event('widgetQuery', {
      action: 'group_change',
      label: 'active',
    });
    toggleGroup?.({ id: totalMapActivityGroup.id, param: 'active' });
  }, [toggleGroup]);

  const handleToggleShowGlobalLabels = useCallback(() => {
    Analytics.event('widgetQuery', {
      action: 'group_change',
      label: 'showLabel',
    });
    toggleRoot?.({
      param: 'showLabel',
      value: !isGlobalLabels,
      ignoreCluster: !isGlobalLabels,
      ignoreIndeterminate: true,
    });
    setIsGlobalLabels(!isGlobalLabels);
  }, [toggleRoot, isGlobalLabels]);

  const handleToggleGlobalActive = useCallback(() => {
    Analytics.event('widgetQuery', {
      action: 'group_change',
      label: 'active',
    });
    toggleRoot?.({
      param: 'active',
      value: !isGlobalActive,
      ignoreCluster: !isGlobalActive,
      ignoreIndeterminate: true,
    });
    setIsGlobalActive(!isGlobalActive);
    if (showTotalActivity !== null) {
      segmentTree.toggleGroup({
        id: totalMapActivityGroup.id,
        param: 'active',
        value: showTotalActivity,
      });
    }
  }, [toggleRoot, isGlobalActive, showTotalActivity, segmentTree]);

  const absolutePosition =
    (menuRef.current?.getBoundingClientRect().x ?? 0) > window.innerWidth / 2
      ? { right: 0 }
      : { left: 0 };
  return (
    <Box position="relative" ref={ref}>
      <Flex
        h="36px"
        w="36px"
        borderRadius={8}
        bg={gray6}
        border={`1px solid ${gray4}`}
        align="center"
        justify="center"
        cursor="pointer"
        onClick={() => setShowSegmentSelection(!showSegmentSelection)}
        ref={menuRef}
      >
        <Icon
          icon={withLabelOption ? TagIcon : FilterIcon}
          fill={showSegmentSelection ? primary : gray1}
          boxSize={withLabelOption ? 5 : 4}
        />
      </Flex>
      {showSegmentSelection && (
        <Box
          position="absolute"
          top="50px"
          {...absolutePosition}
          boxShadow={boxShadows.card}
          w="282px"
          bg={white}
          borderRadius={8}
          zIndex={100}
          maxH="294px"
          overflow="auto"
        >
          <Flex bg={gray5} p={2} pl={2} align="center" gap={2}>
            <Flex
              gap={1}
              align="center"
              flexGrow={1}
              cursor={!!clusters?.length ? 'pointer' : 'default'}
              onClick={handleToggleExpandGroups}
            >
              {!!clusters?.length && (
                <ToggleGroupsExpanded isExpanded={isGlobalExpanded} />
              )}
              <BodyText fontWeight={500}>
                Groups {clusters?.length ? '& Clusters' : ''}
              </BodyText>
            </Flex>
            {withTotalActivityOption && (
              <Tooltip
                label={
                  showTotalActivity
                    ? 'Hide total activity'
                    : 'Show total activity'
                }
                variant="monochrome"
                hasArrow={false}
              >
                <Flex
                  p={1}
                  onClick={handleToggleShowTotalActivity}
                  filter={showTotalActivity ? undefined : 'grayscale(100%)'}
                  as="button"
                >
                  <Image
                    src={TotalActivityBoxImg}
                    alt="total activity"
                    height={12}
                    width={12}
                  />
                </Flex>
              </Tooltip>
            )}
            <Flex align="center" gap={2}>
              {withLabelOption && (
                <Icon
                  icon={isGlobalLabels ? TagIcon : TagHiddenIcon}
                  boxSize={4}
                  cursor="pointer"
                  fill={gray1}
                  onClick={handleToggleShowGlobalLabels}
                />
              )}
              {withActiveOption && (
                <Tooltip
                  label={isGlobalActive ? 'Hide All' : 'Show All'}
                  variant="monochrome"
                  hasArrow={false}
                >
                  <Icon
                    icon={isGlobalActive ? EyeIcon : EyeHiddenIcon}
                    boxSize={4}
                    cursor="pointer"
                    fill={gray1}
                    onClick={handleToggleGlobalActive}
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
          <Box overflow="auto">
            {groups.map((group) => (
              <GroupTreeItem
                key={group.id}
                group={group}
                withLabelOption={withLabelOption}
                withActiveOption={withActiveOption}
              >
                {clustersByGroup[group.id]?.map((cluster) => (
                  <ClusterTreeItem
                    key={cluster.id}
                    cluster={cluster}
                    withLabelOption={withLabelOption}
                    withActiveOption={withActiveOption}
                  />
                ))}
              </GroupTreeItem>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

function ToggleGroupsExpanded({ isExpanded }: { isExpanded: boolean }) {
  const white = useColorModeValue(colors.warmGray[5], colors.coolGray[2]);
  const black = useColorModeValue(colors.black, colors.white);
  return (
    <Tooltip
      label={isExpanded ? 'Collapse All' : 'Expand All'}
      variant="monochrome"
      hasArrow={false}
    >
      <Center h={4} w={4} bg={white}>
        <Center
          h="10px"
          w="10px"
          borderRadius={2}
          bg={black}
          transform={`rotate(${isExpanded ? '90deg' : '0deg'})`}
          transition="0.2s"
        >
          <FontAwesomeIcon icon={faCaretRight} color={white} size="xs" />
        </Center>
      </Center>
    </Tooltip>
  );
}
