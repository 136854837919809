import type { MapCluster, MapGroup, MapInfo } from '~/types/graphika-types';
import { keyframes } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { differenceInDays, subDays } from 'date-fns';
import {
  AutoGrowTextArea,
  BodyText,
  Box,
  createVoyagerToast,
  Flex,
  Icon,
} from '~/components';
import {
  WidgetParams,
  type UseDynamicWidgetHook,
} from '~/components/elements/Editor/plugins/dynamic-insights';
import { DocsLinkPopover } from '~/components/docs';
import { useMaps, useNarrativeFeeds } from '~/queries';
import {
  formatApiUTCDate,
  getMapEndDateUTC,
  getMapStartDateUTC,
  CORE_API_DATA_RETENTION,
} from '~/lib/date';
import { useImmerState } from '~/lib/state-utils';
import { useIsNavigator } from '~/lib/hooks';
import { shallowEquals, useColorModeValues } from '~/lib/utils';
import RefetchIcon from '~/public/icons/Refetch.svg';
import { colors } from '~/styles';
import { DateRangePicker } from './DateRangePicker';
import { MapSelect } from './MapSelect';
import SegmentFilter from './SegmentFilter';

const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;

type Props = {
  widget: UseDynamicWidgetHook;
  insightId: number;
  clusters?: MapCluster[];
  groups: MapGroup[];
  showDatesWithoutSearchTerm?: boolean;
  withActiveOption?: boolean;
  withLabelOption?: boolean;
  withTotalActivityOption?: boolean;
  originalTerm: string | undefined;
};

/** Note: Must wrap with a `SegmentTreeProvider` to support segment filter functionality */
export function QueryBuilder({
  widget: useDynamicWidget,
  insightId,
  clusters,
  groups,
  showDatesWithoutSearchTerm,
  withActiveOption,
  withLabelOption,
  withTotalActivityOption,
  originalTerm,
}: Props) {
  const { toast } = createVoyagerToast();
  const { params, setParams, data, mode, ...widget } =
    useDynamicWidget<WidgetParams<'activityChart'>>();
  const isNavigator = useIsNavigator();
  const { data: feeds = [] } = useNarrativeFeeds();
  const { data: maps = [], isLoading: isLoadingMaps } = useMaps(
    undefined,
    undefined,
    {
      select(data) {
        const telescopeFeedIds = feeds
          .filter((feed) => feed.type === 'custom')
          .map((feed) => feed.id);
        return data.filter(
          (map) =>
            !isNavigator ||
            map.narrative_feed_ids?.some((id) => !telescopeFeedIds.includes(id))
        );
      },
    }
  );
  const selectedMap = useMemo(
    () => maps.find((map) => map.id === params.mapId),
    [maps, params.mapId]
  );
  const liveMaps = useMemo(() => maps.filter((map) => map.is_live), [maps]);
  const userHasAccessToMap = useMemo(
    () => !!liveMaps.find((map) => map.id === selectedMap?.id),
    [liveMaps, selectedMap]
  );
  const [currentParams, setCurrentParams] = useImmerState(params);
  const isFetching = widget.status === 'pending';

  const [primary, gray1, gray4, gray6] = useColorModeValues(
    [colors.g.primary, colors.g.light],
    [colors.warmGray[1], colors.coolGray[5]],
    [colors.warmGray[4], colors.coolGray[3]],
    [colors.warmGray[6], colors.coolGray[2]]
  );
  const spinAnimation = `${spin} infinite 2s linear`;

  const handleTermSubmit = (term: string) => {
    if (!isValidSyntax(term)) {
      toast({
        title:
          'Invalid query syntax. Please ensure that all quotes and parentheses are closed.',
        status: 'error',
      });
      return;
    }
    const isOutOfRange =
      params.startDate &&
      differenceInDays(new Date(), new Date(params.startDate)) >
        CORE_API_DATA_RETENTION;
    if (originalTerm !== term && isOutOfRange) {
      setParams((params) => {
        params.startDate = formatApiUTCDate(
          subDays(new Date(), CORE_API_DATA_RETENTION)
        );
        params.endDate = formatApiUTCDate(new Date());
        params.term = term;
      });
    } else {
      setParams((params) => {
        params.term = term;
      });
    }
  };

  const handleMapSelect = (map: MapInfo) => {
    setParams((params) => {
      params.mapId = map.id;
      params.startDate = params.startDate ?? getMapStartDateUTC(map);
      params.endDate = params.endDate ?? getMapEndDateUTC(map);
    });
  };

  const handleDateRangeSelect = ([startDate, endDate]: [Date, Date]) => {
    setParams((params) => {
      params.startDate = formatApiUTCDate(startDate);
      params.endDate = formatApiUTCDate(endDate);
    });
  };

  useEffect(() => {
    setCurrentParams(params);
  }, [params, setCurrentParams]);

  const hasParamsChanged = !shallowEquals(currentParams, {
    ...params,
    term: params.term ?? '',
  });

  if (!params.mapId) {
    return (
      <Box my={4}>
        <MapSelect
          value={selectedMap}
          onChange={handleMapSelect}
          maps={liveMaps}
        />
      </Box>
    );
  }

  return (
    <Flex mt={4} gap={2} direction="column">
      <Flex align="flex-start" gap={2} position="relative">
        <BodyText minW="43px" mt={2}>
          Use of
        </BodyText>
        <AutoGrowTextArea
          value={params.term ?? ''}
          onSubmit={handleTermSubmit}
          onChange={(value) =>
            setCurrentParams((state) => {
              state.term = value;
            })
          }
          placeholder="Enter your query"
          disabled={!userHasAccessToMap && !isFetching}
          tooltipText="Explore mode not available for this map because you do not have map access"
        />
        {mode === 'reader' && (
          <Box
            position="absolute"
            right="12px"
            top="12px"
            className="hide-on-pdf"
          >
            <DocsLinkPopover
              placement="start-end"
              text="<p>Query accepts common Boolean operators and syntax.<br/><a href='/docs?subsection=Query%20Syntax'>Learn more</a></p>"
              boxSize={3}
            />
          </Box>
        )}
      </Flex>
      <Flex gap={2} justify="space-between" align="center" flexWrap="wrap">
        <Flex align="center">
          <BodyText minW="50px">in</BodyText>
          {!!liveMaps.length && (
            <>
              <MapSelect
                value={selectedMap}
                onChange={handleMapSelect}
                maps={liveMaps}
                isLoading={isLoadingMaps}
              />
              {(showDatesWithoutSearchTerm || !!currentParams.term?.length) && (
                <DateRangePicker
                  map={selectedMap!}
                  value={[params.startDate, params.endDate]}
                  onChange={handleDateRangeSelect}
                  disabled={!userHasAccessToMap && !isFetching}
                />
              )}
            </>
          )}
        </Flex>
        {!!selectedMap && params.startDate && params.endDate && (
          <Flex gap={2} className="hide-on-pdf">
            <Flex
              h="36px"
              w="36px"
              borderRadius={8}
              bg={gray6}
              border={`1px solid ${gray4}`}
              align="center"
              justify="center"
              as="button"
              onClick={() => widget.refetch({ insightId })}
              disabled={!hasParamsChanged}
              cursor={!hasParamsChanged ? 'not-allowed' : undefined}
            >
              <Icon
                icon={RefetchIcon}
                fill={hasParamsChanged || isFetching ? primary : gray1}
                boxSize={4}
                animation={isFetching ? spinAnimation : undefined}
              />
            </Flex>
            <SegmentFilter
              clusters={clusters}
              groups={groups}
              withActiveOption={withActiveOption}
              withLabelOption={withLabelOption}
              withTotalActivityOption={withTotalActivityOption}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

const isValidSyntax = (queryTerm: string) => {
  let quoteCounter = 0;
  let parenthesisCounter = 0;
  let insideQuote = false;

  for (const char of queryTerm) {
    if (char === '"') {
      if (!insideQuote) {
        quoteCounter++;
      } else {
        quoteCounter--;
      }
      insideQuote = !insideQuote;
    } else if (char === '(' && !insideQuote) {
      parenthesisCounter++;
    } else if (char === ')' && !insideQuote) {
      parenthesisCounter--;
    }
  }
  return quoteCounter === 0 && parenthesisCounter === 0;
};
